<button [disabled]="disableValidateButton || checkForm()" (click)="validate()" class="btn btn-primary">{{l(actionButtonStructure.componentSettings.actionButtonLabelKey)}}</button>
<br />
<br />
<kendo-textarea [rows]="5"
                [placeholder]="l('ConnectionMessages')"
                [readonly]="true"
                [style.width.vw]="50"
                resizable="none">
</kendo-textarea>
<br />
<br />
<button [disabled]="disableValidateButton" (click)="redirectPage()" class="btn btn-primary">{{l("Previous")}}</button>