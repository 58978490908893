import { Component, Input, forwardRef, Output, EventEmitter, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { PDFProgressData } from 'ng2-pdf-viewer';
import { saveAs } from '@progress/kendo-file-saver';
import { round as _round } from 'lodash-es';
import { ToolBarToolComponent } from '@progress/kendo-angular-toolbar';

@Component({
    providers: [{ provide: { NG_VALUE_ACCESSOR, ToolBarToolComponent }, useExisting: forwardRef(() => SlsPdfViewer) }],
    selector: 'sls-pdf-viewer',
    template: `
            <div *ngIf="pdfSrc === ''" class="freeze-ui" data-text="LOADING..." style="position: absolute;">
            </div>
            <div class="sls-pdf-viewer">
                <kendo-toolbar id="sls-pdf-viewer-kendo-toolbar">
                    <kendo-toolbar-button [icon]="'arrow-end-left'" [title]="l('First')" (click)="firstPage()" [disabled]="page <= 1"></kendo-toolbar-button>
                    <kendo-toolbar-button [icon]="'arrow-60-left'" [title]="l('Previous')" (click)="prevPage()" [disabled]="page <= 1"></kendo-toolbar-button>
                    <kendo-toolbar-separator></kendo-toolbar-separator>
                    <toolbar-pager [(page)]="page" [totalPage]="totalPage"></toolbar-pager>
                    <kendo-toolbar-separator></kendo-toolbar-separator>
                    <kendo-toolbar-button [icon]="'arrow-60-right'" [title]="l('Next')" (click)="nextPage()" [disabled]="page >= totalPage"></kendo-toolbar-button>
                    <kendo-toolbar-button [icon]="'arrow-end-right'" [title]="l('Last')" (click)="lastPage()" [disabled]="page >= totalPage"></kendo-toolbar-button>
                    <kendo-toolbar-separator></kendo-toolbar-separator>
                    <kendo-toolbar-button [icon]="'minus'" [title]="l('ZoomOut')" (click)="zoomOut()" [disabled]="totalPage == 0 || zoom <= 0.6"></kendo-toolbar-button>
                    <kendo-toolbar-button [icon]="'zoom-out'" [title]="l('ResetZoom')" (click)="resetZoom()" [disabled]="totalPage == 0 || zoom <= 0.6"></kendo-toolbar-button>
                    <kendo-toolbar-button [icon]="'plus'" [title]="l('ZoomIn')" (click)="zoomIn()" [disabled]="totalPage == 0 || zoom >= 5"></kendo-toolbar-button>
                    <kendo-toolbar-separator></kendo-toolbar-separator>
                    <kendo-toolbar-button [icon]="'rotate'" [title]="l('RotateClockwise')" (click)="rotateClockwise()"></kendo-toolbar-button>

                    <kendo-toolbar-button [icon]="'download'" [title]="l('Download')" (click)="downloadPdf()" [disabled]="totalPage == 0"></kendo-toolbar-button>
                    <kendo-toolbar-button [icon]="'hyperlink-open'" [title]="l('OpenInNewWindow')" (click)="openPdfNewTab()" [disabled]="totalPage == 0"></kendo-toolbar-button>
                    <kendo-toolbar-button [icon]="'close'" [title]="l('Close')" (click)="closePdfViewer()"></kendo-toolbar-button>
                </kendo-toolbar>

                <div style="display:block">
                    <pdf-viewer
                        [src]="pdfSrc"
                        [show-all]="false"
                        [page]="page"
                        [zoom]="zoom"
                        [original-size]="true"
                        [fit-to-page]="true"
                        [rotation]="step"
                        (on-progress)="pdfLoading($event)"
                        (after-load-complete)="afterLoadComplete($event)"
                        (page-rendered)="pageRendered($event)"
                        style="max-height: auto;display:block">
                    </pdf-viewer>
                </div>
            </div>
            `
})
export class SlsPdfViewer extends AppComponentBase {
    @Input() public pdfSrc: string = "";
    @Input() public itemName: string = "";

    page: number = 0;
    totalPage: number = 0;
    zoom: number = 1;
    step: number = 0;

    constructor(
        injector: Injector) {
        super(injector);
    }

    @Output() onProgress: EventEmitter<any> = new EventEmitter();
    pdfLoading(progressData: PDFProgressData): void {
        this.onProgress.emit();
    }

    @Output() onClose: EventEmitter<any> = new EventEmitter();
    closePdfViewer(): void {
        this.onClose.emit();
    }

    @Output() onOpenPdf: EventEmitter<any> = new EventEmitter();
    openPdfNewTab(): void {
        window.open().document.body.innerHTML = '<html><head><title>' + this.itemName + '</title></head><body onload="UpdateTitle()"><embed src="' + this.pdfSrc + '" style="width:100%; height:100%" data-name="' + this.itemName + '"/></body></html>';
        this.onOpenPdf.emit();
    }

    afterLoadComplete(pdfData: any): void {
        this.totalPage = pdfData.numPages;
        this.zoom = 1;

        let ng2PdfViewerContainer = document.getElementsByClassName("ng2-pdf-viewer-container")[0] as HTMLElement;
        let grid = document.getElementById("one-model-grid");
        ng2PdfViewerContainer.style.maxHeight = grid.style.maxHeight;

        setTimeout(() => {
            this.gridIsLoading(false);
        }, 1500);
    }

    pageRendered(e) {
        if (this.page === 0) {
            this.page = 1;
        }
    }

    downloadPdf(): void {
        saveAs(this.pdfSrc, this.itemName + '.pdf');
    }

    firstPage(): void {
        this.page = 1;
    }

    nextPage(): void {
        this.page++;
    }

    prevPage(): void {
        this.page--;
    }

    lastPage(): void {
        this.page = this.totalPage;
    }

    zoomIn(): void {
        if (this.zoom < 5) {
            this.zoom = _round(this.zoom + 0.1, 1);
        }
    }

    resetZoom(): void {
        if (this.zoom !== 1) {
            this.zoom = 1;
        }
    }

    zoomOut(): void {
        if (this.zoom > 0.6) {
            this.zoom = _round(this.zoom - 0.1, 1);
        }
    }

    rotateClockwise(): void {
        if (this.step === 270) {
            this.step = 0;
        } else {
            this.step += 90;
        }
    }
}
