<div class="m-content h-100 w-100">
    <div class="m-portlet m-portlet--mobile">
        
        <kendo-treelist #treelist
                        kendoTreeListExpandable
                        [data]="rootData"
                        idField="id"
                        [fetchChildren]="fetchChildren"
                        [hasChildren]="hasChildren"
                        (cellClick)="cellClickHandler($event)"
                        (cellClose)="cellCloseHandler($event)"
                        (add)="addHandler($event)"
                        (remove)="removeHandler($event)"
                        (save)="saveHandler($event)"
                        (cancel)="cancelHandler($event)">

            <ng-template kendoTreeListToolbarTemplate>
                <button kendoTreeListAddCommand type="button">{{l("AddNewGroupFieldSet")}}</button>
            </ng-template>

            <kendo-treelist-column *ngFor="let col of gridSettings?.columnsConfig; let i=index"
                                    [expandable]="i === 0"
                                    [field]="col.field"
                                    [title]="col.title"
                                    [width]="col.width"
                                    [format]="col.format"
                                    [editor]="col.editor">
                
                <!-- kendoTreeListCellTemplate -->
                <ng-template *ngIf="col.field === 'dateCreated' || col.field === 'dateModified'" kendoTreeListCellTemplate let-dataItem="dataItem">
                    <div [class]="col.class">
                        {{formatDate(dataItem[col.field])}}
                    </div>
                </ng-template>

                <ng-template *ngIf="col.editor === 'boolean'" kendoTreeListCellTemplate let-dataItem="dataItem">
                    <div class="k-form-field text-center">
                        <input type="checkbox" class="k-checkbox" id="{{dataItem.id}}" [(ngModel)]="dataItem[col.field]" [disabled]="true" />
                        <label class="k-checkbox-label" for="{{dataItem.id}}"></label>
                    </div>
                </ng-template>

                <ng-template *ngIf="col.editor === 'combobox'" kendoTreeListCellTemplate let-dataItem>
                    {{getItemValue(col, dataItem[col.field])}}
                </ng-template>

                <ng-template *ngIf="col.editor !== 'boolean'"
                            kendoTreeListCellTemplate
                            let-isNew="isNew"
                            let-cellContext="cellContext"
                            let-dataItem="dataItem">
                            {{dataItem[col.field]}}
                </ng-template>

                <!-- kendoTreeListEditTemplate -->
                <ng-template *ngIf="col.editor === 'combobox' && this.formGroup !== undefined && this.formGroup.get(col.field) !== null" kendoTreeListEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                    <!-- <kendo-combobox [disabled]="disableComboBox(col.field)"
                                    [data]="retrieveComboBoxData(col.dataSourceName)"
                                    [textField]="col.textField"
                                    [valueField]="col.valueField"
                                    [valuePrimitive]="true"
                                    [formControl]="formGroup.get(col.field)"
                                    (valueChange)="handleComboBoxChange(col.field)">
                    </kendo-combobox> -->
                </ng-template>

                <ng-template *ngIf="col.editor === 'boolean'" kendoTreeListEditTemplate let-dataItem="dataItem">
                    <div class="k-form-field text-center">
                        <input type="checkbox" class="k-checkbox" id="{{dataItem.id}}" [(ngModel)]="dataItem[col.field]" />
                        <label class="k-checkbox-label" for="{{dataItem.id}}"></label>
                    </div>
                </ng-template>

                <ng-template *ngIf="col.field === 'dateCreated' || col.field === 'dateModified'" kendoTreeListEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                    <div [class]="col.class">
                        {{formatDate(dataItem[col.field])}}
                    </div>
                </ng-template>

                <!-- <ng-template *ngIf="col.editor === 'combobox'"
                             kendoTreeListEditTemplate
                             let-dataItem="dataItem"
                             let-column="column"
                             let-formGroup="formGroup">
                    <kendo-autocomplete
                        placeholder=""
                        [data]="autocompleteData"
                        [formControl]="formGroup.get(col.field)">
                    </kendo-autocomplete>
                </ng-template> -->

            </kendo-treelist-column>

            <kendo-treelist-command-column width="195">
                <ng-template kendoTreeListCellTemplate let-isNew="isNew" let-cellContext="cellContext" let-dataItem="dataItem">
                    <button [kendoTreeListAddCommand]="cellContext" [title]="l('AddChild')">{{l('AddChild')}}</button>
                    <button [kendoTreeListRemoveCommand]="cellContext" [title]="l('Remove')">{{l('Remove')}}</button>
                    <button [kendoTreeListSaveCommand]="cellContext" [disabled]="formGroup?.invalid" [title]="l('Add')">{{l('Add')}}</button>
                    <button [kendoTreeListCancelCommand]="cellContext" title="{{ isNew ? 'Discard changes' : 'Cancel' }}">{{ isNew ? l('DiscardChanges') : l('Cancel') }}</button>
                </ng-template>
            </kendo-treelist-command-column>
        </kendo-treelist>

        <grid-context-menu [for]="treelist" [menuItems]="getGridContextMenuItems(treelistName)" (select)="onSelect($event)">
        </grid-context-menu>

    </div>
</div>