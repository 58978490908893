import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';
import { DynamicDataServiceProxy, RetrieveDynamicDataRequest } from '../../../../shared/service-proxies/service-proxies';

@Injectable()
export class AppNavigationService {

    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService,
        private _dynamicDataServiceProxy: DynamicDataServiceProxy
    ) {

    }

    async getMenu(applyPostSignupMenu: boolean = false): Promise<AppMenu> {
        var appMenu = new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('PaymentDashboard', 'Pages.PaymentDashboard', 'flaticon-line-graph', '/app/main/payment-dashboard'),
            new AppMenuItem('APAging', 'Pages.APAging', 'flaticon-clock-2', '/app/main/ap-aging'),
            new AppMenuItem('SelectPayments', 'Pages.SelectPayments', 'flaticon-add', '/app/main/select-payments/full', [], [
                new AppMenuItem('NormalView', 'Pages.SelectPayments', 'flaticon-add', '/app/main/select-payments/full'),
                new AppMenuItem('ScrollView', 'Pages.SelectPayments', 'flaticon-add', '/app/main/select-payments/detail')
            ]),
            new AppMenuItem('ApprovePayments', 'Pages.ApprovePayments', 'flaticon-list', '/app/main/approve-payments/full', [], [
                new AppMenuItem('NormalView', 'Pages.ApprovePayments', 'flaticon-list', '/app/main/approve-payments/full'),
                new AppMenuItem('ScrollView', 'Pages.ApprovePayments', 'flaticon-list', '/app/main/approve-payments/detail')
            ]),
            new AppMenuItem('SyncPayments', 'Pages.SyncPayments', 'flaticon-open-box', '/app/main/sync-payments'),
            new AppMenuItem('ExportPayments', 'Pages.ExportPayments', 'flaticon-open-box', '/app/main/export-payments'),
            new AppMenuItem('ViewHistory', 'Pages.ViewHistory', 'flaticon-interface-3', '/app/main/view-history'),
            new AppMenuItem('OneTimePayments', 'Pages.CreateOneTimePayment', 'flaticon-plus', '/app/main/one-time-payments'),
            new AppMenuItem('PaymentTemplates', 'Pages.PaymentTemplates', 'flaticon-interface-11', '/app/main/payment-templates'),
            new AppMenuItem('PaymentTemplatesCalendar', 'Pages.CalendarPaymentTemplates', 'flaticon-calendar-2', '/app/main/payment-templates-calendar'),
            new AppMenuItem('ScheduledPayments', 'Pages.ScheduledPayments', 'flaticon-time-3', '/app/main/scheduled-payments'),
            new AppMenuItem('DynamicPageMaintenance', 'Pages.Administration.Host.Settings', 'flaticon-map', '/app/main/admin-page/dynamic-page-maintenance'),
            new AppMenuItem('DynamicFormMaintenance', 'Pages.Administration.Host.Settings', 'flaticon-folder-1', '/app/main/admin-page/dynamic-form-maintenance'),
            new AppMenuItem('DynamicGridMaintenance', 'Pages.Administration.Host.Settings', 'flaticon2-grids', '/app/main/admin-page/dynamic-grid-maintenance'),
            new AppMenuItem('DynamicActionButtonMaintenance', 'Pages.Administration.Host.Settings', 'flaticon-add-circular-button', '/app/main/admin-page/dynamic-action-button-maintenance'),
            new AppMenuItem('DynamicNavigationMaintenance', 'Pages.Administration.Host.Settings', 'flaticon-menu-1', '/app/main/admin-page/dynamic-navigation-maintenance'),
            new AppMenuItem('DynamicRoles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/dynamicRoles'),
            new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages', ['/app/admin/languages/{name}/texts']),
            new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants')
        ]);

        var adminItems = [
            //new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
            //new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
            //new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
            new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'flaticon-folder-1', '/app/admin/auditLogs'),
            //new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
            //new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
            //new AppMenuItem('WebhookSubscriptions', 'Pages.Administration.WebhookSubscription', 'flaticon2-world', '/app/admin/webhook-subscriptions'),
            //new AppMenuItem('DynamicProperties', 'Pages.Administration.DynamicProperties', 'flaticon-interface-8', '/app/admin/dynamic-property'),
            new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
            new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings')
        ];

        // This is done for configuring subdomains as a new user
        if (applyPostSignupMenu) {
            appMenu = new AppMenu('MainMenu', 'MainMenu', [
                new AppMenuItem('AdministratorInformation', '', 'flaticon-home', '', [], [
                    new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings', [], [], null, null, !this._appSessionService.tenant.logoId || !this._appSessionService.tenant.loginLogoId),
                    new AppMenuItem('BankAccountMaintenance', '', 'flaticon-network', '/app/main/dynamic-page/sls-bank-accounts-maintenance', [], [], null, null, !this._appSessionService.completedBankMaintenance),
                    new AppMenuItem('ClientMasterSettingsMaintenance', '', 'flaticon-business', '/app/main/dynamic-page/client-admin---client-master-settings-maintenance', [], [], null, null, !this._appSessionService.completedClientMaintenance, !this._appSessionService.completedBankMaintenance),
                    new AppMenuItem('EntityMaintenance', '', 'fas fa-user-tie', '/app/main/dynamic-page/client-admin---entity-maintenance', [], [], null, null, !this._appSessionService.completedEntityMaintenance),
                    new AppMenuItem('UserMaintenance', '', 'flaticon-avatar', '/app/main/dynamic-page/client-admin---user-maintenance', [], [], null, null, !this._appSessionService.completedUserMaintenance)
                ])
            ]);
        }
        else {
            let response = undefined;
            var request = new RetrieveDynamicDataRequest();
            request.dataSourceName = "GridDataDynamicNavigationMaintenance";
            request.data = {
                filterByClientAndERP: true
            };

            await this._dynamicDataServiceProxy.retrieveDynamicData(request).toPromise()
                .then(result => { response = result; })
                .catch(err => { });

            if (this._appSessionService.tenant?.name !== undefined) {
                appMenu.items = appMenu.items.filter(x => !x.name.toLowerCase().includes("dynamic") && !x.name.toLowerCase().includes("maintenance"));
                if (response !== undefined) {
                    response.data.items = response.data.items.filter(x => x.sharedWithClient);
                }
            }
            else {
                if (response !== undefined) {
                    response.data.items = response.data.items.filter(x => !x.sharedWithClient);
                }
            }

            if (response !== undefined) {
                for (var item of response?.data?.items) {
                    let appMenuItem = new AppMenuItem(item.menuNameLabelKey, '', item.menuIcon, item.route);
                    if (item.gridPopUpAttachedDynamicNavigationComponents.length > 0) {
                        appMenuItem.route = '';
                        appMenuItem.items.push(...this.retrieveChildItems(item.gridPopUpAttachedDynamicNavigationComponents));
                    }

                    if (item.menuNameLabelKey === "Administration") {
                        appMenuItem.items.push(...adminItems);
                    }

                    appMenu.items.push(appMenuItem);
                }
            }

            if (appMenu.items.findIndex(x => x.name === "Administration") === -1) {
                appMenu.items.push(new AppMenuItem('Administration', '', 'flaticon-interface-8', '', [], adminItems));
            }
        }

        return appMenu;
    }

    retrieveChildItems(children) {
        let appMenuItems: AppMenuItem[] = [];
        for (var child of children) {
            let appMenuItem = new AppMenuItem(child.menuNameLabelKey, '', child.menuIcon, child.route);
            if (child.gridPopUpAttachedDynamicNavigationComponents.length > 0) {
                appMenuItem.route = '';
                appMenuItem.items.push(...this.retrieveChildItems(child.gridPopUpAttachedDynamicNavigationComponents));
            }

            appMenuItems.push(appMenuItem);
        }

        return appMenuItems;
    }

    checkChildMenuItemPermission(menuItem): boolean {

        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }
        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let allMenuItems: AppMenuItem[] = [];
        this.getMenu().then(value => {
            let menu = value;
            menu.items.forEach(menuItem => {
                allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
            });
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach(subMenu => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
