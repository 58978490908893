import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppNavigationService } from '@app/shared/layout/nav/app-navigation.service';
import { StreamlineaCommonModule } from '@shared/common/common.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule, BsDatepickerConfig, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { CommonLookupModalComponent } from './lookup/common-lookup-modal.component';
import { EntityTypeHistoryModalComponent } from './entityHistory/entity-type-history-modal.component';
import { EntityChangeDetailModalComponent } from './entityHistory/entity-change-detail-modal.component';
import { DateRangePickerInitialValueSetterDirective } from './timing/date-range-picker-initial-value.directive';
import { DatePickerInitialValueSetterDirective } from './timing/date-picker-initial-value.directive';
import { DateTimeService } from './timing/date-time.service';
import { TimeZoneComboComponent } from './timing/timezone-combo.component';
import { CustomizableDashboardComponent } from './customizable-dashboard/customizable-dashboard.component';
import { WidgetGeneralStatsComponent } from './customizable-dashboard/widgets/widget-general-stats/widget-general-stats.component';
import { DashboardViewConfigurationService } from './customizable-dashboard/dashboard-view-configuration.service';
import { GridsterModule } from 'angular-gridster2';
import { WidgetDailySalesComponent } from './customizable-dashboard/widgets/widget-daily-sales/widget-daily-sales.component';
import { WidgetEditionStatisticsComponent } from './customizable-dashboard/widgets/widget-edition-statistics/widget-edition-statistics.component';
import { WidgetHostTopStatsComponent } from './customizable-dashboard/widgets/widget-host-top-stats/widget-host-top-stats.component';
import { WidgetIncomeStatisticsComponent } from './customizable-dashboard/widgets/widget-income-statistics/widget-income-statistics.component';
import { WidgetMemberActivityComponent } from './customizable-dashboard/widgets/widget-member-activity/widget-member-activity.component';
import { WidgetProfitShareComponent } from './customizable-dashboard/widgets/widget-profit-share/widget-profit-share.component';
import { WidgetRecentTenantsComponent } from './customizable-dashboard/widgets/widget-recent-tenants/widget-recent-tenants.component';
import { WidgetRegionalStatsComponent } from './customizable-dashboard/widgets/widget-regional-stats/widget-regional-stats.component';
import { WidgetSalesSummaryComponent } from './customizable-dashboard/widgets/widget-sales-summary/widget-sales-summary.component';
import { WidgetSubscriptionExpiringTenantsComponent } from './customizable-dashboard/widgets/widget-subscription-expiring-tenants/widget-subscription-expiring-tenants.component';
import { WidgetTopStatsComponent } from './customizable-dashboard/widgets/widget-top-stats/widget-top-stats.component';
import { FilterDateRangePickerComponent } from './customizable-dashboard/filters/filter-date-range-picker/filter-date-range-picker.component';
import { AddWidgetModalComponent } from './customizable-dashboard/add-widget-modal/add-widget-modal.component';
import { PieChartModule, AreaChartModule, LineChartModule, BarChartModule } from '@swimlane/ngx-charts';
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { UnsavedChangesComponent } from './unsaved-changes/unsaved-changes.component';
import { CountoModule } from 'angular2-counto';
import { AppBsModalModule } from '@shared/common/appBsModal/app-bs-modal.module';
import { SingleLineStringInputTypeComponent } from './input-types/single-line-string-input-type/single-line-string-input-type.component';
import { ComboboxInputTypeComponent } from './input-types/combobox-input-type/combobox-input-type.component';
import { CheckboxInputTypeComponent } from './input-types/checkbox-input-type/checkbox-input-type.component';
import { MultipleSelectComboboxInputTypeComponent } from './input-types/multiple-select-combobox-input-type/multiple-select-combobox-input-type.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PasswordInputWithShowButtonComponent } from './password-input-with-show-button/password-input-with-show-button.component';
import { KeyValueListManagerComponent } from './key-value-list-manager/key-value-list-manager.component';
import { SubHeaderComponent } from '@app/shared/common/sub-header/sub-header.component';
import { DynamicGridComponent } from './dynamic-components/dynamic-grid/dynamic-grid.component';
import { DynamicFormComponent } from './dynamic-components/dynamic-form/dynamic-form.component';
import { DynamicPageComponent } from './dynamic-components/dynamic-page/dynamic-page.component';
import { DynamicComponentComponent } from './dynamic-components/dynamic-component/dynamic-component.component';
import { DynamicActionButtonComponent } from './dynamic-components/dynamic-action-button/dynamic-action-button.component';
import { DynamicSaveChangesButtonComponent } from './dynamic-components/dynamic-save-changes-button/dynamic-save-changes-button.component';
import { DynamicPagePopUpComponent } from './dynamic-components/dynamic-page-pop-up/dynamic-page-pop-up.component';
import { DynamicComponentOldComponent } from './dynamic-components/dynamic-component-old/dynamic-component-old.component';
import { DynamicPageModalComponent } from './dynamic-page-modal/dynamic-page-modal.component';
import { DynamicPdfAndImageViewer } from './dynamic-pdf-and-image-viewer/dynamic-pdf-and-image-viewer.component';
import { DynamicTreeListComponent } from './dynamic-components/dynamic-treelist/dynamic-treelist.component';
import { FilterValuesPipe } from './dynamic-components/shared/filter-values.pipe';
import { CacheDataService } from './cache-data/cache-data.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { DynamicModule } from 'ng-dynamic-component';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { ContextMenuModule } from '@progress/kendo-angular-menu';
import { FileUploadModule } from 'ng2-file-upload';
import { FileUploadModule as PrimeNgFileUploadModule } from 'primeng/fileupload';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { IntlModule } from '@progress/kendo-angular-intl';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
import { SlsPdfViewer } from './sls-pdf-viewer.component';
import { ToolbarPagerComponent } from './toolbar-pager.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { PopupModule } from '@progress/kendo-angular-popup';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { GridContextMenuComponent } from './grid-context-menu/grid-context-menu.component';
import { DateFormatPipe } from './date-format.pipe';
import { NumberFormatPipe } from './number-format.pipe';
import { ItemValuePipe } from './item-value.pipe';
import { DateNumericStringFormatPipe } from './date-numeric-string-format.pipe';
import { CustomKendoPagerInfoComponent } from './custom-kendo-pager-info/custom-kendo-pager-info.component';
import { ExportToExcelComponent } from './export-to-excel/export-to-excel.component';
import { GridFilterComponent } from './grid-filter/grid-filter.component';
import { UnsavedChangesGuard } from './unsaved-changes-guard/unsaved-changes-guard';
import { ClientAndErpFilterComponent } from './client-and-erp-filter/client-and-erp-filter.component';
import { DynamicResetPasswordButtonComponent } from './dynamic-components/dynamic-reset-password-button/dynamic-reset-password-button.component';
import { DynamicGeneratePasswordButtonComponent } from './dynamic-components/dynamic-generate-password-button/dynamic-generate-password-button.component';
import { DynamicGenerateActivationCodeButtonComponent } from './dynamic-components/dynamic-generate-activation-code-button/dynamic-generate-activation-code-button.component';
import { DynamicActivateUserButtonComponent } from './dynamic-components/dynamic-activate-user-button/dynamic-activate-user-button.component';
import { DynamicUploadButtonComponent } from './dynamic-components/dynamic-upload-button/dynamic-upload-button.component';
import { DynamicValidateIntacctComponent } from './dynamic-components/dynamic-validate-intacct/dynamic-validate-intacct.component';
import { DynamicImportDataComponent } from './dynamic-components/dynamic-import-data/dynamic-import-data.component';

@NgModule({
    imports: [
        TooltipsModule,
        TreeListModule,
        UploadsModule,
        ChartsModule,
        TreeViewModule,
        ContextMenuModule,
        FileUploadModule,
        PrimeNgFileUploadModule,
        DialogsModule,
        IntlModule,
        ButtonsModule,
        DateInputsModule,
        DropDownsModule,
        InputsModule,
        LayoutModule,
        GridModule,
        ExcelModule,
        ToolBarModule,
        SchedulerModule,
        PdfViewerModule,
        PDFExportModule,
        PopupModule,
        DynamicModule,
        TooltipModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        UtilsModule,
        StreamlineaCommonModule,
        TableModule,
        PaginatorModule,
        GridsterModule,
        TabsModule.forRoot(),
        BsDropdownModule.forRoot(),
        PieChartModule,
        AreaChartModule,
        LineChartModule,
        BarChartModule,
        BsDatepickerModule.forRoot(),
        PerfectScrollbarModule,
        CountoModule,
        AppBsModalModule,
        AutoCompleteModule,
        PopoverModule.forRoot()
    ],
    declarations: [
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        CustomizableDashboardComponent,
        WidgetGeneralStatsComponent,
        WidgetDailySalesComponent,
        WidgetEditionStatisticsComponent,
        WidgetHostTopStatsComponent,
        WidgetIncomeStatisticsComponent,
        WidgetMemberActivityComponent,
        WidgetProfitShareComponent,
        WidgetRecentTenantsComponent,
        WidgetRegionalStatsComponent,
        WidgetSalesSummaryComponent,
        WidgetSubscriptionExpiringTenantsComponent,
        WidgetTopStatsComponent,
        FilterDateRangePickerComponent,
        AddWidgetModalComponent,
        SingleLineStringInputTypeComponent,
        ComboboxInputTypeComponent,
        CheckboxInputTypeComponent,
        CustomKendoPagerInfoComponent,
        GridFilterComponent,
        MultipleSelectComboboxInputTypeComponent,
        PasswordInputWithShowButtonComponent,
        KeyValueListManagerComponent,
        SubHeaderComponent,
        UnsavedChangesComponent,
        DynamicGridComponent,
        DynamicFormComponent,
        DynamicPageComponent,
        DynamicComponentComponent,
        DynamicComponentOldComponent,
        DynamicPageModalComponent,
        DynamicActionButtonComponent,
        DynamicSaveChangesButtonComponent,
        DynamicPagePopUpComponent,
        ToolbarPagerComponent,
        SlsPdfViewer,
        DynamicPdfAndImageViewer,
        DynamicTreeListComponent,
        ExportToExcelComponent,
        GridContextMenuComponent,
        FilterValuesPipe,
        DateFormatPipe,
        NumberFormatPipe,
        ItemValuePipe,
        DateNumericStringFormatPipe,
        ClientAndErpFilterComponent,
        DynamicResetPasswordButtonComponent,
        DynamicGeneratePasswordButtonComponent,
        DynamicGenerateActivationCodeButtonComponent,
        DynamicActivateUserButtonComponent,
        DynamicUploadButtonComponent,
        DynamicValidateIntacctComponent,
        DynamicImportDataComponent,
    ],
    exports: [
        TooltipsModule,
        CommonModule,
        FormsModule,
        TreeListModule,
        UploadsModule,
        ChartsModule,
        TreeViewModule,
        ContextMenuModule,
        FileUploadModule,
        PrimeNgFileUploadModule,
        DialogsModule,
        IntlModule,
        ButtonsModule,
        DateInputsModule,
        DropDownsModule,
        InputsModule,
        LayoutModule,
        GridModule,
        ExcelModule,
        ToolBarModule,
        SchedulerModule,
        PdfViewerModule,
        PDFExportModule,
        PopupModule,
        DynamicModule,
        TooltipModule,
        PopoverModule,
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        CustomizableDashboardComponent,
        PasswordInputWithShowButtonComponent,
        KeyValueListManagerComponent,
        SubHeaderComponent,
        UnsavedChangesComponent,
        AddWidgetModalComponent,
        AppBsModalModule,
        GridsterModule,
        GridFilterComponent,
        CustomKendoPagerInfoComponent,
        PerfectScrollbarModule,
        DynamicGridComponent,
        DynamicFormComponent,
        DynamicPageComponent,
        DynamicComponentComponent,
        DynamicComponentOldComponent,
        DynamicActionButtonComponent,
        DynamicSaveChangesButtonComponent,
        DynamicPagePopUpComponent,
        DynamicPageModalComponent,
        ExportToExcelComponent,
        ToolbarPagerComponent,
        SlsPdfViewer,
        DynamicPdfAndImageViewer,
        DynamicTreeListComponent,
        GridContextMenuComponent,
        FilterValuesPipe,
        DateFormatPipe,
        NumberFormatPipe,
        ItemValuePipe,
        DateNumericStringFormatPipe,
        ClientAndErpFilterComponent
    ],
    providers: [
        DateTimeService,
        AppLocalizationService,
        AppNavigationService,
        DashboardViewConfigurationService,
        CacheDataService,
        UnsavedChangesGuard,
        { provide: BsDatepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerConfig },
        { provide: BsLocaleService, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerLocale },
        { provide: BsDaterangepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDaterangepickerConfig }
    ],

    entryComponents: [
        WidgetGeneralStatsComponent,
        WidgetDailySalesComponent,
        WidgetEditionStatisticsComponent,
        WidgetHostTopStatsComponent,
        WidgetIncomeStatisticsComponent,
        WidgetMemberActivityComponent,
        WidgetProfitShareComponent,
        WidgetRecentTenantsComponent,
        WidgetRegionalStatsComponent,
        WidgetSalesSummaryComponent,
        WidgetSubscriptionExpiringTenantsComponent,
        WidgetTopStatsComponent,
        FilterDateRangePickerComponent,
        SingleLineStringInputTypeComponent,
        ComboboxInputTypeComponent,
        CheckboxInputTypeComponent,
        MultipleSelectComboboxInputTypeComponent
    ]
})
export class AppCommonModule {
    static forRoot(): ModuleWithProviders<AppCommonModule> {
        return {
            ngModule: AppCommonModule,
            providers: [
                AppAuthService,
                AppRouteGuard
            ]
        };
    }
}
