import { AppComponentBase } from "@shared/common/app-component-base";
import { Component, Injector, Output, EventEmitter, Input, HostListener, ChangeDetectorRef } from "@angular/core";
import { clone as _clone } from 'lodash-es';
import { finalize } from 'rxjs/operators';
import { DynamicDataServiceProxy, RetrieveDynamicDataRequest } from "@shared/service-proxies/service-proxies";

@Component({
    selector: 'client-and-erp-filter',
    templateUrl: './client-and-erp-filter.component.html',
    styleUrls: ['./client-and-erp-filter.component.scss']
})

export class ClientAndErpFilterComponent extends AppComponentBase {
    @Input() clients: any[] = [];
    @Input() erps: any[] = [];
    @Output() getSelectedClientAndErpEmitter: EventEmitter<any> = new EventEmitter<any>();

    selectedClient: any;
    selectedErp: any;
    longestErpRetrieved: boolean = false;
    longestClientRetrieved: boolean = false;
    clientStyleApplied: boolean = false;
    erpStyleApplied: boolean = false;
    erpWidth: number = 54;
    clientWidth: number = 54;
    listHeight: number = 0;

    erpId: number = 0;
    clientId: number = 0;

    constructor(
        injector: Injector,
        private cd: ChangeDetectorRef,
        private _dynamicDataServiceProxy: DynamicDataServiceProxy
    ) {
        super(injector);
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.computeComboboxListHeight();
    }

    ngOnInit(): void {
        this.computeComboboxListHeight();
        this.getAllDropDownData();
    }

    computeComboboxListHeight(): void {
        const listHeight = window.innerHeight - 250;
        this.listHeight = listHeight < 80 ? 80 : listHeight;
    }

    getAllDropDownData() {
        let data = {};

        data['dataSourceNames'] = ['KeyValuePairErpList', 'KeyValuePairClientList'];

        let request = new RetrieveDynamicDataRequest();
        request.dataSourceName = "GetAllDropDownData";
        request.data = data;

        this._dynamicDataServiceProxy.retrieveDynamicData(request)
            .pipe(
                finalize(() => {
                    this.cd.markForCheck();
                })
            ).subscribe(result => {
                if (result.data !== null) {
                    result.data.items.forEach(item => {
                        item = this.formatItemDateWithoutId(item);

                        if (item.dataSourceName === "KeyValuePairClientList") {
                            this.clients = item.dataSourceItems.slice(1);
                        }

                        if (item.dataSourceName === "KeyValuePairErpList") {
                            this.erps = item.dataSourceItems.slice(1);
                        }

                        if (!this.permission.isGranted('Pages.Administration.Host.Settings')) {
                            const selectedClient = this.clients.filter(x => x.name.toLowerCase().includes(this.appSession.tenant.name.toLowerCase()));

                            if (selectedClient.length > 0) {
                                this.selectedClient = selectedClient[0];
                                this.clientAndErpService.clientId = this.selectedClient.id;
                            }

                            const selectedErp = this.erps.filter(x => x.id.toString() === result.data.componentSettings?.erpTypeId?.toString());

                            if (selectedErp.length > 0) {
                                this.selectedErp = selectedErp[0];

                                this.clientAndErpService.erpId = this.selectedErp.id;
                            }

                            this.emitGetSelectedClientAndErp();
                        }
                    });
                }
            });
    }

    ngAfterViewChecked(): void {
        let clientComboBox = document.getElementById("client-combobox-data-filter");
        let erpComboBox = document.getElementById("erp-combobox-data-filter");

        if (this.erps.length >= 1 && this.longestErpRetrieved == false) {
            this.erpWidth = 54;

            let longestErp = this.erps[0].name;

            for (let i = 1; i < this.erps.length; i++) {
                if (this.erps[i].name.length > longestErp.length) {
                    longestErp = this.erps[i].name;
                }
            }

            this.longestErpRetrieved = true;
            for (let j = 0; j < longestErp.length; j++) {
                if (longestErp[j] == ' ') {
                    this.erpWidth += 4;
                }
                else {
                    this.erpWidth += 8;
                }
            }

            if (erpComboBox != undefined) {
                erpComboBox.setAttribute("style", "width:" + this.erpWidth + "px");
            }

            this.erpStyleApplied = false;
            this.clientStyleApplied = false;
        }

        if (this.clients.length > 1 && this.longestClientRetrieved == false) {
            this.clientWidth = 54;
            let longestClient = this.clients[0].name;

            for (let i = 1; i < this.clients.length; i++) {
                if (this.clients[i].name.length > longestClient.length) {
                    longestClient = this.clients[i].name;
                }
            }

            this.longestClientRetrieved = true;
            for (let j = 0; j < longestClient.length; j++) {
                if (longestClient[j] == ' ') {
                    this.clientWidth += 4;
                }
                else {
                    this.clientWidth += 8;
                }
            }

            clientComboBox.setAttribute("style", "width:" + this.clientWidth + "px");

            this.erpStyleApplied = false;
            this.clientStyleApplied = false;
        }

        if (erpComboBox !== undefined && erpComboBox !== null && this.erpStyleApplied === false) {
            erpComboBox.setAttribute("style", "width:" + this.erpWidth + "px");
            this.erpStyleApplied = true;
        } else {
            this.erpStyleApplied = true;
        }

        if (clientComboBox !== undefined && clientComboBox !== null && this.clientStyleApplied === false) {
            clientComboBox.setAttribute("style", "width:" + this.clientWidth + "px");
            this.clientStyleApplied = true;
        } else {
            this.clientStyleApplied = true;
        }
    }

    clientChange(comboBoxControl = null): void {
        if (comboBoxControl !== null && comboBoxControl.dataItem !== undefined) {
            comboBoxControl.dataItem = comboBoxControl._previousDataItem;
            comboBoxControl._text = comboBoxControl._previousDataItem.Name;

            this.selectedClient = comboBoxControl.dataItem;

            this.clientAndErpService.clientId = this.selectedClient?.id ?? 0;
            this.clientAndErpService.erpId = this.selectedErp?.id ?? 0;

            this.emitGetSelectedClientAndErp();
        }
    }

    erpChange(comboBoxControl = null): void {
        if (comboBoxControl !== null && comboBoxControl.dataItem !== undefined) {
            comboBoxControl.dataItem = comboBoxControl._previousDataItem;
            comboBoxControl._text = comboBoxControl._previousDataItem.Name;

            this.selectedErp = comboBoxControl.dataItem;

            this.clientAndErpService.clientId = this.selectedClient?.id ?? 0;
            this.clientAndErpService.erpId = this.selectedErp?.id ?? 0;

            this.emitGetSelectedClientAndErp();
        }
    }

    emitGetSelectedClientAndErp(): void {
        if (this.clientAndErpService.clientId === 0 || this.clientAndErpService.erpId === 0) {
            return;
        }

        this.getSelectedClientAndErpEmitter.emit({
            selectedClientId: this.clientAndErpService.clientId,
            selectedErpId: this.clientAndErpService.erpId
        });
    }
}