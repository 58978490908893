import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, QueryList, TemplateRef, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { merge as _merge, uniqBy as _uniqBy } from 'lodash-es';
import { DateTime } from 'luxon';
import { FormGroup } from '@angular/forms';
import { CustomTemplate } from '@app/shared/common/dynamic-components/dynamic-grid/custom-template';
import { DynamicGridComponent } from '@app/shared/common/dynamic-components/dynamic-grid/dynamic-grid.component';
import { DynamicFormComponent } from '@app/shared/common/dynamic-components/dynamic-form/dynamic-form.component';
import { catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { DynamicDataServiceProxy, SaveDynamicDataRequest } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'dynamic-component-old',
    templateUrl: './dynamic-component-old.component.html',
    styleUrls: ['./dynamic-component-old.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicComponentOldComponent extends AppComponentBase {
    @ViewChildren('dynamicGrid') dynamicGridComponents: QueryList<DynamicGridComponent>;
    @ViewChildren('dynamicForm') dynamicFormComponents: QueryList<DynamicFormComponent>;

    @Input() pageId: number = 0;
    @Input() gridStructures: any;
    @Input() formStructures: any;
    @Input() gridsData: any;
    @Input() formsData: any;
    @Input() pageName: string;
    @Input() useParentComponentData: boolean = false;
    @Input() parentItem: any;

    @Input() fieldLabelWidth: number = 0;
    @Input() fieldControlWidth: number = 0;
    @Input() groupFieldSetWidth: number = 0;
    @Input() groupFieldSetGap: number = 0;
    @Input() showBorder: boolean = false;
    @Input() showGuide: boolean = false;

    convertedPageName: string;
    gridName: string;
    gridStructure: any;
    formStructure: any;
    dataAsOf: DateTime;
    selectedItemIds: string[] = [];
    editedRowIndex: number;
    customTemplates: CustomTemplate[] = [];
    @ViewChild('customBillNumber') public customBillNumber: TemplateRef<any>;
    @ViewChild('customPayCheckBox') public customPayCheckBox: TemplateRef<any>;
    @ViewChild('customMenuIcon') public customMenuIcon: TemplateRef<any>;
    formGroup: FormGroup;
    gridComponents: any[] = [];
    formComponents: any[] = [];
    retrieveDataBy: string;
    buttonDisabled: boolean = false;

    constructor(
        injector: Injector,
        private cd: ChangeDetectorRef,
        private _dynamicDataServiceProxy: DynamicDataServiceProxy
    ) {
        super(injector);
    }

    formData(formName: string): any {
        // console.log("formData - formName");
        // console.log(formName);

        //if (this.formsData !== undefined) {
        //    console.log("this.formsData");
        //    console.log(this.formsData);
        //}

        // this.formsData.filter(x => x.);
    }

    onFieldLabelWidthValueChange(value: any): void {
        this.fieldLabelWidth = value;
        this.cd.markForCheck();
    }

    onFieldControlWidthValueChange(value: any): void {
        this.fieldControlWidth = value;
        this.cd.markForCheck();
    }

    onGroupFieldSetWidthValueChange(value: any): void {
        this.groupFieldSetWidth = value;
        this.cd.markForCheck();
    }

    onGridGapValueChange(value: any): void {
        this.groupFieldSetGap = value;
        this.cd.markForCheck();
    }

    ngOnChanges() {
    }

    ngOnInit() {
    }

    ngDoCheck() {
    }

    ngAfterContentInit() {
    }

    ngAfterContentChecked() {
    }

    ngAfterViewInit() {
        this.addCustomTemplates();
    }

    ngAfterViewChecked() {
    }

    ngOnDestroy() {
    }

    addCustomTemplates() {
    }

    cellClick($event) {
    }

    getData($event): void {
        this.dataAsOf = $event.dataAsOf;
    }

    saveDynamicForm(): void {

    }

    confirmPublishChanges() {
        this.message.confirm(
            this.l("AreYouSureYouWantToPublishChanges"),
            this.l("PublishChanges"),
            isConfirmed => {
                if (isConfirmed) {
                    this.publishChanges();
                } else {

                }
            },
        );
    }

    publishChanges() {
        this.gridIsLoading();
        this.buttonDisabled = true;
        let request = new SaveDynamicDataRequest();
        let data = {};

        //NOTE: loop multiple grid components
        if (this.dynamicGridComponents.length > 0) {
            let gridComponents: any[] = [];

            this.dynamicGridComponents.forEach(dynamicGridComponent => {
                if (dynamicGridComponent !== undefined) {
                    let grid = {};

                    if (this.pageName === "DynamicPageMaintenance") {
                        dynamicGridComponent.gridDetailsDynamicGridComponents.forEach(gridDetailsDynamicGridComponent => {
                            if (gridDetailsDynamicGridComponent.gridName == "AttachDynamicForm") {
                                grid['deletedIdsForAttachDynamicForm'] = gridDetailsDynamicGridComponent.deletedIds;
                                grid['addedAndUpdatedItemsForAttachDynamicForm'] = gridDetailsDynamicGridComponent.items;
                            }
                            else if (gridDetailsDynamicGridComponent.gridName == "AttachDynamicGrid") {
                                grid['deletedIdsForAttachDynamicGrid'] = gridDetailsDynamicGridComponent.deletedIds;
                                grid['addedAndUpdatedItemsForAttachDynamicGrid'] = gridDetailsDynamicGridComponent.items;
                            }
                        });

                        data["selectedClientId"] = dynamicGridComponent.selectedClientId;
                        data["selectedErpId"] = dynamicGridComponent.selectedErpId;
                    }
                    else if (this.pageName === "DynamicFormMaintenance") {
                        dynamicGridComponent.gridDetailsDynamicGridComponents.forEach(gridDetailsDynamicGridComponent => {
                            if (gridDetailsDynamicGridComponent.gridName == "AttachDynamicForm") {
                                grid['deletedIdsForFormPopUpAttachDynamicForm'] = gridDetailsDynamicGridComponent.deletedIds;
                            }
                            else if (gridDetailsDynamicGridComponent.gridName == "AttachDynamicGrid") {
                                grid['deletedIdsForFormPopUpAttachDynamicGrid'] = gridDetailsDynamicGridComponent.deletedIds;
                            }
                        });
                    }
                    else if (this.pageName === "DynamicGridMaintenance") {
                        dynamicGridComponent.gridDetailsDynamicGridComponents.forEach(gridDetailsDynamicGridComponent => {
                            if (gridDetailsDynamicGridComponent.gridName == "AttachGridPopUpDynamicForm") {
                                grid['deletedIdsForGridPopUpAttachDynamicForm'] = gridDetailsDynamicGridComponent.deletedIds;
                            }
                            else if (gridDetailsDynamicGridComponent.gridName == "AttachGridPopUpDynamicGrid") {
                                grid['deletedIdsForGridPopUpAttachDynamicGrid'] = gridDetailsDynamicGridComponent.deletedIds;
                            }
                            else if (gridDetailsDynamicGridComponent.gridName == "AttachGridDetailsDynamicForm") {
                                grid['deletedIdsForGridDetailsAttachDynamicForm'] = gridDetailsDynamicGridComponent.deletedIds;
                            }
                            else if (gridDetailsDynamicGridComponent.gridName == "AttachGridDetailsDynamicGrid") {
                                grid['deletedIdsForGridDetailsAttachDynamicGrid'] = gridDetailsDynamicGridComponent.deletedIds;
                            }
                        });
                    }
                    else if (this.pageName === "DynamicFormDesignMaintenance") {
                        dynamicGridComponent.gridDetailsDynamicGridComponents.forEach(gridDetailsDynamicGridComponent => {

                            if (gridDetailsDynamicGridComponent.gridName == "DynamicFieldMaintenance") {

                                if (gridDetailsDynamicGridComponent.deletedIds.length > 0) {
                                    if (grid['deletedIdsForDynamicFields'] === undefined) {
                                        grid['deletedIdsForDynamicFields'] = gridDetailsDynamicGridComponent.deletedIds;
                                    }
                                    else {
                                        grid['deletedIdsForDynamicFields'].push(...gridDetailsDynamicGridComponent.deletedIds);
                                    }
                                }

                                const addedAndUpdatedItems = gridDetailsDynamicGridComponent.addedItems.concat(gridDetailsDynamicGridComponent.updatedItems);

                                if (addedAndUpdatedItems.length > 0) {
                                    const uniqueItems = _uniqBy(addedAndUpdatedItems, 'parentId');

                                    uniqueItems.forEach(item => {
                                        const toBeAdded = dynamicGridComponent.items.filter(x => x.id === item["parentId"])[0];

                                        if (toBeAdded !== undefined) {
                                            const distinctItem = addedAndUpdatedItems.filter(x => x.id === toBeAdded.id).length === 0;
                                            const notYetAdded = dynamicGridComponent.updatedItems.filter(x => x.id === toBeAdded.id).length === 0;

                                            if (distinctItem && notYetAdded) {
                                                dynamicGridComponent.updatedItems.push(toBeAdded);
                                            }
                                        }
                                    });
                                }

                            }
                            else if (gridDetailsDynamicGridComponent.gridName == "DynamicFormDesignMaintenance") {

                                gridDetailsDynamicGridComponent.gridDetailsDynamicGridComponents.forEach(childGridDetailsDynamicGridComponent => {

                                    if (childGridDetailsDynamicGridComponent.gridName == "DynamicFieldMaintenance") {

                                        if (childGridDetailsDynamicGridComponent.deletedIds.length > 0) {
                                            if (grid['deletedIdsForDynamicFields'] === undefined) {
                                                grid['deletedIdsForDynamicFields'] = childGridDetailsDynamicGridComponent.deletedIds;
                                            }
                                            else {
                                                grid['deletedIdsForDynamicFields'].push(...childGridDetailsDynamicGridComponent.deletedIds);
                                            }
                                        }

                                        const addedAndUpdatedItems = childGridDetailsDynamicGridComponent.addedItems.concat(childGridDetailsDynamicGridComponent.updatedItems);

                                        if (addedAndUpdatedItems.length > 0) {
                                            const uniqueItems = _uniqBy(addedAndUpdatedItems, 'parentId');

                                            uniqueItems.forEach(item => {

                                                dynamicGridComponent.items.filter(toBeAdded => {
                                                    toBeAdded.childGroupFieldSets.filter(y => {

                                                        if (y.id === item["parentId"]) {
                                                            const distinctItem = addedAndUpdatedItems.filter(z => z.id === toBeAdded.id).length === 0;
                                                            const notYetAdded = dynamicGridComponent.updatedItems.filter(a => a.id === toBeAdded.id).length === 0;

                                                            if (distinctItem && notYetAdded) {
                                                                dynamicGridComponent.updatedItems.push(toBeAdded);
                                                            }
                                                        }
                                                    });
                                                });

                                            });
                                        }

                                    }

                                });


                                if (gridDetailsDynamicGridComponent.deletedIds.length > 0) {
                                    if (grid['deletedIdsForChildGroupFieldSets'] === undefined) {
                                        grid['deletedIdsForChildGroupFieldSets'] = gridDetailsDynamicGridComponent.deletedIds;
                                    }
                                    else {
                                        grid['deletedIdsForChildGroupFieldSets'].push(...gridDetailsDynamicGridComponent.deletedIds);
                                    }
                                }

                                const addedAndUpdatedItems = gridDetailsDynamicGridComponent.addedItems.concat(gridDetailsDynamicGridComponent.updatedItems);

                                if (addedAndUpdatedItems.length > 0) {
                                    const uniqueItems = _uniqBy(addedAndUpdatedItems, 'parentId');

                                    uniqueItems.forEach(item => {
                                        const toBeAdded = dynamicGridComponent.items.filter(x => x.id === item["parentId"])[0];

                                        if (toBeAdded !== undefined) {
                                            const distinctItem = addedAndUpdatedItems.filter(x => x.id === toBeAdded.id).length === 0;
                                            const notYetAdded = dynamicGridComponent.updatedItems.filter(a => a.id === toBeAdded.id).length === 0;

                                            if (distinctItem && notYetAdded) {
                                                dynamicGridComponent.updatedItems.push(toBeAdded);
                                            }
                                        }
                                    });
                                }
                            }

                        });
                    }

                    //NOTE: Assign parentId
                    if (this.retrieveDataBy !== undefined) {
                        if (dynamicGridComponent.items !== undefined) {
                            dynamicGridComponent.items.map(x => {
                                x.parentId = this.retrieveDataBy;
                            });
                        }
                    }

                    //TODO: Add support for multiple grid returnedData
                    grid['gridName'] = dynamicGridComponent.gridStructureDataSourceName;
                    grid['gridDataDataSourceName'] = dynamicGridComponent.gridDataDataSourceName;
                    grid['gridStructure'] = dynamicGridComponent.gridStructure;

                    grid['items'] = dynamicGridComponent.items;
                    grid['deletedIds'] = dynamicGridComponent.deletedIds;

                    if (this.pageName !== "DynamicNavigationMaintenance") {
                        grid['addedAndUpdatedItems'] = dynamicGridComponent.addedItems.concat(dynamicGridComponent.updatedItems);
                    }
                    else {
                        grid['addedAndUpdatedItems'] = dynamicGridComponent.items;
                        this.updateDisplayOrder(grid['addedAndUpdatedItems']);
                    }

                    gridComponents.push(grid);
                }
            });

            data['gridComponents'] = gridComponents;
        }

        //NOTE: loop multiple form components
        if (this.dynamicFormComponents.length > 0) {
            let formComponents: any[] = [];

            this.dynamicFormComponents.forEach(dynamicFormComponent => {
                console.log("dynamicFormComponent");
                console.log(dynamicFormComponent);

                if (dynamicFormComponent !== undefined) {
                    let form = {};

                    form['parentId'] = this.retrieveDataBy;
                    form['formStructure'] = dynamicFormComponent.formStructure;
                    form['formName'] = dynamicFormComponent.formStructureDataSourceName;
                    form['value'] = dynamicFormComponent.dynamicForm?.value;

                    formComponents.push(form);

                    console.log("dynamicFormComponent?.kendoUploadAttachments");
                    console.log(dynamicFormComponent?.kendoUploadAttachments);

                    if (dynamicFormComponent?.kendoUploadAttachments !== undefined) {
                        console.log("dynamicFormComponent?.kendoUploadAttachments['fileList']?._files");
                        console.log(dynamicFormComponent?.kendoUploadAttachments["fileList"]?._files);
                    }

                }
            });

            data['formComponents'] = formComponents;
        }

        data['parentId'] = this.retrieveDataBy;
        data['old-component'] = true;

        request.action = "save";
        request.dataSourceName = this.pageName;
        request.data = data;

        // console.log("request");
        // console.log(request);
        // this.gridIsLoading(false);
        // return;

        this._dynamicDataServiceProxy.saveDynamicData(request)
            .pipe(
                catchError(() => {
                    return of(undefined);
                }),
                finalize(() => {
                    this.buttonDisabled = false;
                    this.gridIsLoading(false);
                    this.cd.markForCheck();
                })
            ).subscribe(result => {
                if (result.data && Object.keys(result.data).length === 0 && Object.getPrototypeOf(result.data) === Object.prototype) {
                    this.notify.success(this.l("ChangesSuccessfullyPublished"));
                }

                if (result.data.isSuccess) {
                    this.dynamicGridComponents.forEach(dynamicGridComponent => {
                        if (dynamicGridComponent !== undefined) {
                            if (result.data.returnedData.length > 0) {
                                const filteredItems = dynamicGridComponent.items.filter(x => x.id !== "");
                                dynamicGridComponent.items = filteredItems;

                                if (this.pageName === "DynamicFormDesignMaintenance") {

                                    result.data.returnedData.forEach(item => {
                                        item = this.formatItemDateWithoutId(item);

                                        if (item.dynamicFields !== undefined) {
                                            item.dynamicFields.forEach(item => {
                                                item = this.formatItemDateWithoutId(item);
                                            });
                                        }

                                        if (item.childGroupFieldSets !== undefined) {
                                            item.childGroupFieldSets.forEach(item => {
                                                item = this.formatItemDateWithoutId(item);

                                                if (item.dynamicFields !== undefined) {
                                                    item.dynamicFields.forEach(item => {
                                                        item = this.formatItemDateWithoutId(item);
                                                    });
                                                }
                                            });
                                        }
                                    });

                                    dynamicGridComponent.items = result.data.returnedData;
                                }
                                else if (this.pageName !== "DynamicNavigationMaintenance") {
                                    result.data.returnedData.forEach(returnedItem => {
                                        for (var key in returnedItem) {
                                            if (key.toLowerCase().includes("date") == true && key.includes("Id") == false) {
                                                returnedItem[key] = returnedItem[key] ? DateTime.fromISO(returnedItem[key]) : <any>undefined;
                                            }
                                        }

                                        //NOTE: Support for concurrency. Replace item from dynamicGridComponent.items if the item is there
                                        const itemsIndex = dynamicGridComponent.items.findIndex(x => x.id === returnedItem.id);
                                        const itemExists = itemsIndex !== -1;
                                        if (itemExists) {
                                            dynamicGridComponent.items.splice(itemsIndex, 1);
                                        }
                                        dynamicGridComponent.items.unshift(returnedItem);

                                        dynamicGridComponent.addedItems = [];
                                    });
                                }
                                else {
                                    result.data.returnedData.forEach(returnedItem => {
                                        for (var key in returnedItem) {
                                            if (key.toLowerCase().includes("date") == true && key.includes("Id") == false) {
                                                returnedItem[key] = returnedItem[key] ? DateTime.fromISO(returnedItem[key]) : <any>undefined;
                                            }
                                        }
                                    });

                                    dynamicGridComponent.items = result.data.returnedData;
                                }
                            }

                            dynamicGridComponent.updatedItems = [];

                            dynamicGridComponent.populateGrid();
                        }
                    });

                    if (result.data.validationMessage.length > 0) {
                        result.data.validationMessage.forEach(message => {
                            this.notify.warn(this.l(message));
                        });
                    }
                    else {
                        this.notify.success(this.l("ChangesSuccessfullyPublished"));
                    }
                }
            });
    }

    updateDisplayOrder(list) {
        let counter = 1;
        for (var item of list) {
            item.displayOrder = counter;
            if (item.gridPopUpAttachedDynamicNavigationComponents !== undefined && item.gridPopUpAttachedDynamicNavigationComponents.length > 0) {
                this.updateDisplayOrder(item.gridPopUpAttachedDynamicNavigationComponents);
            }

            counter++;
        }
    }
}
