import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DynamicDataServiceProxy, SaveDynamicDataRequest } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'dynamic-generate-activation-code-button',
    templateUrl: './dynamic-generate-activation-code-button.component.html',
    styleUrls: ['./dynamic-generate-activation-code-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicGenerateActivationCodeButtonComponent extends AppComponentBase {

    @Input() actionButtonStructure: any;
    @Input() dynamicFormComponents: any;
    disableGeneratingButton: boolean = false;

    constructor(
        injector: Injector,
        private cd: ChangeDetectorRef,
        private _dynamicDataServiceProxy: DynamicDataServiceProxy
    ) {
        super(injector);
    }

    generateActivationCode() {
        this.disableGeneratingButton = true;
        let request = new SaveDynamicDataRequest();
        request.dataSourceName = "GenerateActivationCode";
        request.action = "update";
        request.data = {};

        if (this.dynamicFormComponents[0].otherData !== undefined) {
            const urlParams = new URLSearchParams(window.location.search);
            const emailAddressParam = urlParams.get('emailAddress');
            if (emailAddressParam !== null) {
                request.data["emailAddress"] = emailAddressParam;
            }
            else {
                request.data["emailAddress"] = JSON.parse(localStorage.getItem("redirectData"))["sls_user_in_process$email"];
            }

            this._dynamicDataServiceProxy.saveDynamicData(request)
                .pipe(
                    finalize(() => {
                        this.disableGeneratingButton = false;
                        this.cd.markForCheck();
                    })
                ).subscribe(result => {
                    if (result.data.items[0].isSuccess) {
                        this.disableGeneratingButton = true;

                        this.message.success(this.l("GeneratedActivationCode"), this.l("SendNewCode"), { isHtml: true });
                    }
                    else {
                        let displayError = "";
                        result.data.items[0].validationMessage.forEach(error => {
                            displayError += error + "<br><br>";
                        });

                        this.message.error(displayError, this.l('SendNewCode'), { isHtml: true });
                    }
                });
        }
        else {
            this.message.error("EmailNotFound", this.l('SendNewCode'), { isHtml: true });
        }
    }

}
