import { Injectable } from '@angular/core';

@Injectable()
export class ClientAndErpService {
    showClientAndErpDataSimulation: boolean = false;
    isDataSimulation: boolean = false;
    clientId: number = 0;
    erpId: number = 0;

    constructor() { }
}