import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DynamicDataServiceProxy, SaveDynamicDataRequest } from '@shared/service-proxies/service-proxies';
import { of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Component({
    selector: 'dynamic-activate-user-button',
    templateUrl: './dynamic-activate-user-button.component.html',
    styleUrls: ['./dynamic-activate-user-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicActivateUserButtonComponent extends AppComponentBase {

    @Input() actionButtonStructure: any;
    @Input() dynamicFormComponents: any;
    disableActivateUserButton: boolean = false;
    activationCode: string = "";

    constructor(
        injector: Injector,
        private cd: ChangeDetectorRef,
        private _dynamicDataServiceProxy: DynamicDataServiceProxy,
        private router: Router
    ) {
        super(injector);
    }

    activateUser() {
        this.disableActivateUserButton = true;
        let request = new SaveDynamicDataRequest();
        request.dataSourceName = "ActivateUser";
        request.action = "update";
        request.data = {};

        if (this.activationCode !== "") {
            request.data["activationCode"] = this.activationCode;
        }

        if (this.dynamicFormComponents[0].otherData !== undefined) {
            const urlParams = new URLSearchParams(window.location.search);
            const emailAddressParam = urlParams.get('emailAddress');
            if (emailAddressParam !== null) {
                request.data["emailAddress"] = emailAddressParam;
            }
            else {
                request.data["emailAddress"] = JSON.parse(localStorage.getItem("redirectData"))["sls_user_in_process$email"];
            }

            this._dynamicDataServiceProxy.saveDynamicData(request)
                .pipe(
                    catchError(() => {
                        return of(undefined);
                    }),
                    finalize(() => {
                        this.cd.markForCheck();
                    })
                ).subscribe(result => {
                    if (result.data.items[0].isSuccess) {
                        this.disableActivateUserButton = true;

                        this.message.success(this.l("SuccessfullyActivated"), this.l("EmailActivation2"), { isHtml: true });

                        localStorage.clear();

                        this.router.navigate(['app/main/dynamic-page/new-client-setup-process']);
                    }
                    else {
                        this.disableActivateUserButton = false;

                        this.message.warn(this.l("ActivationFailed"), this.l('EmailActivation2'), { isHtml: true });
                    }
                });
        }
        else {
            this.message.error("EmailNotFound", this.l('EmailActivation2'), { isHtml: true });
            this.disableActivateUserButton = false;
        }
    }
}
