import { Pipe, PipeTransform } from '@angular/core';
import { AppComponentBase } from '../../../shared/common/app-component-base';

@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe extends AppComponentBase implements PipeTransform {

    transform(date: any, format?: string): string {
        if (date !== undefined) {
            if (date !== null && date.isLuxonDateTime) {
                date = date.toJSDate();
            }

            if (typeof date === 'string') {
                date = new Date(date);
            }
        }

        if (date instanceof Date && isNaN(date.getTime())) {
            return undefined;
        }

        if (format === undefined) {
            format = 'MM/dd/yyyy';
        }

        return this.intl.formatDate(date, format);
    }

}