import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TextAreaComponent } from '@progress/kendo-angular-inputs';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DynamicDataServiceProxy, SaveDynamicDataRequest } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';
import { DateTime } from 'luxon';

@Component({
    selector: 'dynamic-validate-intacct',
    templateUrl: './dynamic-validate-intacct.component.html',
    styleUrls: ['./dynamic-validate-intacct.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicValidateIntacctComponent extends AppComponentBase {
    @ViewChild(TextAreaComponent) textAreaComponent: TextAreaComponent;
    @Input() actionButtonStructure: any;
    @Input() dynamicFormComponents: any;
    disableValidateButton: boolean = false;
    validationResponse: string = "";
    validationFinished: boolean = false;
    timeValidated: DateTime = null;

    constructor(
        injector: Injector,
        private _dynamicDataServiceProxy: DynamicDataServiceProxy,
        private router: Router,
        private cd: ChangeDetectorRef
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.subEnableButton();
    }

    ngOnDestroy(): void {
        this.unSubEnableButton();
    }

    validate(isInitialCall = true) {
        if (this.timeValidated !== null) {
            if (DateTime.now() > this.timeValidated.plus({ minutes: this.appSession.tenancyName.includes("stage") || this.appSession.tenancyName.includes("test") ? 5 : 15 })) {
                abp.event.trigger("app.enableButton");
                this.message.info(this.l("IntacctValidationExpired"), this.l("IntacctValidation"));
                this.cd.markForCheck();
                return;
            }
        }

        if (isInitialCall) {
            this.dynamicFormComponents[0].dynamicForm?.disable();
            this.message.info(this.l("IntacctValidationMessage"), this.l("IntacctValidation"));
            this.validationResponse = "";
            this.textAreaComponent.updateValue(this.validationResponse);
            this.timeValidated = DateTime.now();
        }

        this.disableValidateButton = true;
        let request = new SaveDynamicDataRequest();
        request.action = "validate";
        request.dataSourceName = "ValidateIntacct";
        request.data = {};

        request.data["isInitialCall"] = isInitialCall;
        request.data["companyId"] = this.dynamicFormComponents[0].dynamicForm.controls['erp_base_unit$erp_base_unit_id'].value;
        request.data["userId"] = this.dynamicFormComponents[0].dynamicForm.controls['sls_user$sls_user_id'].value;
        request.data["userPassword"] = this.dynamicFormComponents[0].dynamicForm.controls['sls_user$password_hash'].value;

        this._dynamicDataServiceProxy.saveDynamicData(request)
            .pipe(
                finalize(() => {
                    if (this.validationFinished) {
                        this.disableValidateButton = false;
                        this.dynamicFormComponents[0].dynamicForm?.enable();
                    }
                })
            ).subscribe(response => {
                if (response.data.isComplete) {
                    if (!response.data.isSuccess) {
                        for (let result of response.data.result.split("\n")) {
                            if (result !== "") {
                                setTimeout(() => {
                                    this.validationResponse += result + "\n";
                                    this.textAreaComponent.updateValue(this.validationResponse);
                                    this.scrollToBottom();
                                }, 1000);
                            }
                        }

                        this.validationFinished = true;
                    }
                    else {
                        for (let result of response.data.result.split("\n")) {
                            if (result !== "") {
                                setTimeout(() => {
                                    this.validationResponse += result + "\n";
                                    this.textAreaComponent.updateValue(this.validationResponse);
                                    this.scrollToBottom();
                                }, 1000);
                            }
                        }

                        this.appSession.connectionVerified = true;
                        this.validationFinished = true;
                    }
                }
                else if (response.data.isSuccess) {
                    this.validationResponse += response.data.result + "\n";
                    this.textAreaComponent.updateValue(this.validationResponse);
                    this.scrollToBottom();
                    setTimeout(() => this.validate(false), 15000);
                }
                else if (!response.data.isSuccess) {
                    this.validationResponse += response.data.result;
                    this.disableValidateButton = false;
                }
            });
    }

    scrollToBottom() {
        let list = document.getElementsByTagName("textarea");
        for (let elem of Array.from(list)) {
            elem.scrollTop = elem.scrollHeight;
        }
    }

    redirectPage() {
        this.appSession.intacctStepNumber = 4;
        this.router.navigate(['app/main/intacct-setup-process']);
    }

    checkForm() {
        return this.dynamicFormComponents !== undefined && !this.dynamicFormComponents[0]?.dynamicForm?.valid;
    }

    subEnableButton() {
        abp.event.on('app.enableButton', this.enableButton);
    }

    unSubEnableButton() {
        abp.event.off('app.enableButton', this.enableButton);
    }

    enableButton = () => {
        this.timeValidated = null;
        this.disableValidateButton = false;
        this.dynamicFormComponents[0].dynamicForm?.enable();
        this.cd.markForCheck();
    }
}
