<div *ngIf="pdfSrc === ''" class="freeze-ui" data-text="LOADING..." style="position: absolute;">
</div>
<div id="dynamic-pdf-viewer" class="sls-pdf-viewer" [style.width]="width">
    <kendo-toolbar *ngIf="hasToolbar" id="sls-pdf-viewer-kendo-toolbar">
        <kendo-toolbar-button *ngIf="hasFirstPageButton" [icon]="'arrow-end-left'" [title]="l('First')" (click)="firstPage()" [disabled]="page <= 1"></kendo-toolbar-button>
        <kendo-toolbar-button *ngIf="hasPreviousPageButton" [icon]="'arrow-60-left'" [title]="l('Previous')" (click)="prevPage()" [disabled]="page <= 1"></kendo-toolbar-button>
        
        <kendo-toolbar-separator *ngIf="hasFirstPageButton || hasPreviousPageButton"></kendo-toolbar-separator>

        <toolbar-pager [(page)]="page" [totalPage]="totalPage"></toolbar-pager>

        <kendo-toolbar-separator *ngIf="hasNextPageButton || hasLastPageButton"></kendo-toolbar-separator>

        <kendo-toolbar-button *ngIf="hasNextPageButton" [icon]="'arrow-60-right'" [title]="l('Next')" (click)="nextPage()"></kendo-toolbar-button>
        <kendo-toolbar-button *ngIf="hasLastPageButton" [icon]="'arrow-end-right'" [title]="l('Last')" (click)="lastPage()" [disabled]="page >= totalPage"></kendo-toolbar-button>
        
        <kendo-toolbar-separator *ngIf="hasZoomOutButton || hasResetZoomButton || hasZoomInButton"></kendo-toolbar-separator>

        <kendo-toolbar-button *ngIf="hasZoomOutButton" [icon]="'minus'" [title]="l('ZoomOut')" (click)="zoomOut()" [disabled]="totalPage == 0 || zoom <= 0.6"></kendo-toolbar-button>
        <kendo-toolbar-button *ngIf="hasResetZoomButton" [icon]="'zoom-out'" [title]="l('ResetZoom')" (click)="resetZoom()" [disabled]="totalPage == 0 || zoom <= 0.6"></kendo-toolbar-button>
        <kendo-toolbar-button *ngIf="hasZoomInButton" [icon]="'plus'" [title]="l('ZoomIn')" (click)="zoomIn()" [disabled]="totalPage == 0 || zoom >= 5"></kendo-toolbar-button>
        
        <kendo-toolbar-separator *ngIf="hasRotateClockWiseButton || hasDownloadButton || hasOpenInNewWindowButton || hasCloseButton"></kendo-toolbar-separator>

        <kendo-toolbar-button *ngIf="hasRotateClockWiseButton" [icon]="'rotate'" [title]="l('RotateClockwise')" (click)="rotateClockwise()"></kendo-toolbar-button>
        
        <kendo-toolbar-button *ngIf="hasDownloadButton" [icon]="'download'" [title]="l('Download')" (click)="downloadPdf()" [disabled]="totalPage == 0"></kendo-toolbar-button>
        <kendo-toolbar-button *ngIf="hasOpenInNewWindowButton" [icon]="'hyperlink-open'" [title]="l('OpenInNewWindow')" (click)="openPdfNewTab()" [disabled]="totalPage == 0"></kendo-toolbar-button>
        <kendo-toolbar-button *ngIf="hasCloseButton" [icon]="'close'" [title]="l('Close')" (click)="closePdfViewer()"></kendo-toolbar-button>
    </kendo-toolbar>

    <div id="pdf-viewer-block">
        <span id="item-header">{{itemHeader}}</span>

        <pdf-viewer *ngIf="attachmentsIsPdf"
                    [src]="pdfSrc"
                    [show-all]="false"
                    [page]="page"
                    [zoom]="zoom"
                    [original-size]="true"
                    [fit-to-page]="true"
                    [rotation]="step"
                    (on-progress)="pdfLoading($event)"
                    (after-load-complete)="afterLoadComplete($event)"
                    (page-rendered)="pageRendered($event)"
                    [style.width]="width"
                    [style.height]="height">
        </pdf-viewer>

        <img *ngIf="!attachmentsIsPdf" id="image-attachment" [src]="pdfSrc" />
    </div>
</div>