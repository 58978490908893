import { Pipe, PipeTransform } from '@angular/core';
import { AppComponentBase } from '../../../shared/common/app-component-base';

@Pipe({
    name: 'dateNumericStringFormat'
})
export class DateNumericStringFormatPipe extends AppComponentBase implements PipeTransform {

    transform(groupValue: any, args: any[]): string {
        let value = "";
        let groupField = args[0];
        let columnSettings = args[1];
        let comboBoxData = args[2] !== undefined ? args[2] : {};

        const field = columnSettings.find((x: { field: string; }) => x.field === groupField);

        if (groupField === "checkNumber" && groupValue === "") {
            value = this.l('Unassigned');
        } else if (groupField === "pay") {
            value = groupValue === true || groupValue === this.l("Selected") ? this.l("Selected") : this.l("Unselected");
        } else if (groupField === "approve") {
            value = groupValue === true || groupValue === this.l("Approved") ? this.l("Approved") : this.l("Unapproved");
        } else if (groupField === "syncedToERP") {
            value = groupValue === true || groupValue === this.l("Synced") ? this.l("SyncedPayments") : this.l("UnsyncedPayments");
        } else if (groupField === "exportToSage") {
            value = groupValue === true || groupValue === this.l("PaymentToExport") ? this.l("PaymentsToExport") : this.l("UnexportedPayments");
        } else if (groupField === "isActive") {
            value = groupValue === true || groupValue === this.l("Active") ? this.l("Active") : this.l("Inactive");
        } else {
            if (field.filter !== undefined) {
                if (field.filter === "date") {
                    value = this.formatDate(groupValue);
                } else if (field.filter === "numeric") {
                    value = this.formatNumber(groupValue);
                }
                else if (comboBoxData[groupField] !== undefined) {
                    var parsedResult = comboBoxData[groupField].find(x => x.id === groupValue || String(x.id) === String(groupValue));
                    if (parsedResult !== undefined) {
                        value = parsedResult.name;
                    }
                    else {
                        value = groupValue;
                    }
                }
                else if (groupValue === undefined) {
                    value = this.l("Undefined");
                }
                else if (groupValue === null) {
                    value = this.l("Null");
                }
                else {
                    value = groupValue;
                }
            }
            else if (groupValue === undefined) {
                value = this.l("Undefined");
            }
            else if (groupValue === null) {
                value = this.l("Null");
            }
            else {
                value = groupValue;
            }
        }

        return value;
    }

}