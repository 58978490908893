<div *ngIf="!isHidden" [perfectScrollbar]="{wheelPropagation: true}">
    <div class="freeze-ui" [ngClass]="{'loading-spinner': pageName.length > 0}" data-text="LOADING..." *ngIf="loadingStateService.isLoading || dynamicGridLoading || gridDependencyLoading || gridUnpopulated">
    </div>
    <div class="ui-overlay" *ngIf="applyOverlay">
    </div>
    <br *ngIf="gridComponentIndex > 0" />
    <div *ngIf="!gridComponent?.labelIsHidden" class="dynamic-grid-label">{{l(gridComponent?.dynamicGridLabelKey)}}</div>
    <div *ngIf="!gridStructure?.componentSettings?.hideLabel" class="dynamic-grid-label">{{l(gridStructure?.componentSettings?.gridLabelKey)}}</div>
    <kendo-dialog [width]="1500" title="Preview" *ngIf="opened" (close)="close()">
        <div id="table-design-preview" class="m-content">
            <!--This is a placeholder for the grid preview-->
        </div>
    </kendo-dialog>

    <kendo-dialog *ngIf="dialogOpened" [maxWidth]="1000" class="z-index-100" title='{{dialogTitle}}' (close)="closeDialog()">
        <dynamicPageModal [modalFormComponents]="modalFormComponents"
                          [modalGridComponents]="modalGridComponents"
                          [formStructureDataSourceName]="modalFormStructureDataSourceName"
                          [formDataDataSourceName]="modalFormDataDataSourceName"
                          [gridStructureDataSourceName]="modalGridStructureDataSourceName"
                          [gridDataDataSourceName]="modalGridDataDataSourceName"
                          [previewGridSettings]="previewGridSettings"
                          [usePreviewGrid]="usePreviewGrid"
                          [parentItem]="parentItem"
                          [actionType]="actionType"
                          [gridStructures]="modalGridStructures"
                          (modalSave)="modalSave($event)">
        </dynamicPageModal>
        <!-- <dynamic-page-pop-up    [pageName]="pageName">
        </dynamic-page-pop-up> -->
        <!-- [gridStructures]="gridStructures"
        [formStructures]="formStructures"
        [fieldLabelWidth]="fieldLabelWidth"
        [fieldControlWidth]="fieldControlWidth"
        [groupFieldSetWidth]="groupFieldSetWidth"
        [groupFieldSetGap]="groupFieldSetGap"
        [showBorder]="showBorder"
        [showGuide]="showGuide" -->
    </kendo-dialog>

    <kendo-splitter id="grid-loading-hook" class="grid-pdf-splitter" [ngClass]="{'dim-payment-details': loadingStateService.isLoading, 'contains-label': gridStructure?.componentSettings !== undefined && !gridStructure?.componentSettings?.hideLabel}">
        <kendo-splitter-pane min="40%" [ngClass]="{'contains-label': gridStructure?.componentSettings !== undefined && !gridStructure?.componentSettings?.hideLabel}">
            <div class="freeze-ui" data-text="LOADING..." style="display: none; position: absolute;">
            </div>

            <export-to-excel *ngIf="hasExportToExcel" (exportToExcel)="exportToExcel()"></export-to-excel>

            <kendo-grid #grid
                        id="dynamic-grid"
                        [hideHeader]="hideHeader"
                        [class.fixed-width]="isPdfViewerVisible"
                        [data]="gridSettings?.gridData"
                        [pageSize]="gridSettings?.state.take"
                        [skip]="gridSettings?.state.skip"
                        [sort]="gridSettings?.state.sort"
                        [filter]="gridSettings?.state.filter"
                        [group]="gridSettings?.state.group"
                        [sortable]="{
                            allowUnsort: true,
                            mode: 'multiple'
                        }"
                        [pageable]="pageable"
                        [selectable]="selectableSettings"
                        filterable="filterable"
                        [resizable]="resizable"
                        [reorderable]="reorderable"
                        [columnMenu]="columnMenu"
                        [groupable]="groupable"
                        [navigable]="navigable"
                        [rowClass]="rowCallback"
                        [style.height.px]="innerGridHeight"
                        (dataStateChange)="dataStateChange($event)"
                        (detailExpand)="detailExpand($event)"
                        (detailCollapse)="detailCollapse()"
                        (columnVisibilityChange)="onOneModelGridVisibilityChange($event, gridSettings)"
                        (columnResize)="onOneModelGridResize($event, gridSettings)"
                        (columnReorder)="onOneModelGridReorder($event, gridSettings)"
                        (filterChange)="oneModelGridFilterChange($event, gridSettings)"
                        (groupChange)="oneModelGridGroupChange($event, gridSettings)"
                        (sortChange)="onOneModelGridSortChange($event, gridSettings)"
                        (cellClick)="cellClick($event)"
                        (dblclick)="dblclick()"
                        (add)="add($event)"
                        (edit)="edit($event)"
                        (cancel)="cancel()"
                        (save)="save($event)"
                        (remove)="remove($event)"
                        (pageChange)="pageChange($event)"
                        (groupCollapse)="groupCollapse($event, gridSettings)"
                        (groupExpand)="groupExpand($event, gridSettings)"
                        (selectionChange)="selectionChange($event)"
                        (keyup)="keyup($event)"
                        (click)="click($event)"
                        (cellClose)="cellClose($event)"
                        (keydown)="keydown(grid, $event)"
                        (paste)="onPaste($event)">

                <ng-template kendoGridNoRecordsTemplate>
                    <div class="text-left">{{loadingStateService.isLoading ? l("LoadingData") : noRecordsAvailableLabel()}}</div>
                </ng-template>

                <ng-template *ngIf="hasToolbar || hasAddNewRecordButton()" kendoGridToolbarTemplate>
                    <button *ngIf="hasToolbarPreviewButton" kendoButton (click)="open()">{{l("Preview")}}</button>
                    <button *ngIf="hasToolbarAddNewRecordButton || hasAddNewRecordButton()" kendoButton (click)="redirectToNewRecordForm()">{{l("AddNewRecord")}}</button>
                </ng-template>

                <ng-template *ngIf="!groupable && (hasExportToExcel || hasGridFilter)" kendoGridToolbarTemplate>
                    <export-to-excel *ngIf="hasExportToExcel" (exportToExcel)="exportToExcel()"></export-to-excel>
                    <grid-filter *ngIf="hasGridFilter" [clients]="clients" [erps]="erps" [selectedClient]="selectedClient" [selectedErp]="selectedErp" [filterDataSourceItems]="filterDataSourceItems" [selectedFilters]="selectedFilters" (updateDataHandler)="updateData($event)"></grid-filter>
                </ng-template>

                <kendo-grid-checkbox-column *ngIf="gridStructure?.componentSettings?.checkBoxOnly" [width]="gridStructure?.componentSettings?.enableSelectAll ? 63 : 28" [showSelectAll]="gridStructure?.componentSettings?.enableSelectAll"></kendo-grid-checkbox-column>

                <kendo-grid-column *ngFor="let col of gridSettings?.columnsConfig; let i=index"
                                   [field]="col.field"
                                   [title]="col.title"
                                   [width]="col.width"
                                   [filter]="col.filter"
                                   [filterable]="col.filterable"
                                   [format]="col.format"
                                   [class]="col.class + ' ' + col.field  + '-grid-column-value'"
                                   [sortable]="col.sortable"
                                   [headerClass]="col.headerClass"
                                   [hidden]="col.hidden"
                                   [includeInChooser]="!col.internalUseOnly"
                                   [editor]="col.editor"
                                   [editable]="col.editable"
                                   [locked]="col?.locked || col?.columnLock">

                    <!-- kendoGridColumnMenuTemplate -->
                    <ng-template *ngIf="col.field !== 'document'" kendoGridColumnMenuTemplate let-service="service">
                        <kendo-grid-columnmenu-sort [service]="service">
                        </kendo-grid-columnmenu-sort>
                        <kendo-grid-columnmenu-chooser [service]="service">
                        </kendo-grid-columnmenu-chooser>
                        <kendo-grid-columnmenu-filter [service]="service" [id]="col.field + '-columnmenu-filter'">
                        </kendo-grid-columnmenu-filter>
                        <button kendoButton *ngIf="!gridSettings.isDefault" [look]="'flat'" (click)="gridResetToDefaultView(service, gridSettings, defaultGridSettings, items)">{{l("DefaultView")}}</button>
                    </ng-template>

                    <ng-template *ngIf="(col.field === 'document' && !gridSettings.isDefault) || ((col.field === 'approve' || col.field === 'pay') && !gridSettings.isDefault)" kendoGridColumnMenuTemplate let-service="service" let-column="column">
                        <button kendoButton [look]="'flat'" (click)="gridResetToDefaultView(service, gridSettings, defaultGridSettings, items)">{{l("DefaultView")}}</button>
                    </ng-template>

                    <!--kendoGridFilterMenuTemplate-->
                    <ng-template *ngIf="col.editor === 'combobox' || col.editor === 'dropdown'"
                                 kendoGridFilterMenuTemplate
                                 let-filter="filter"
                                 let-filterService="filterService">
                        <kendo-multiselect [data]="comboBoxData[col.field]"
                                           [textField]="col.textField"
                                           [valueField]="col.valueField"
                                           [filterable]="true"
                                           [value]="filter | filterValues: col.field : comboBoxData[col.field]"
                                           (filterChange)="onFilterChange($event, col.field)"
                                           (valueChange)="filterCategoryChange($event, col.field, filterService)">
                        </kendo-multiselect>
                    </ng-template>

                    <!-- kendoGridCellTemplate -->
                    <ng-template *ngIf="col.custom === true" kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                        <ng-container *ngTemplateOutlet="columnCustomTemplates[col.field]; context:{ colField: col.field, dataItem: dataItem, rowIndex: rowIndex }">
                        </ng-container>
                    </ng-template>

                    <ng-template *ngIf="col.editor === 'date'" kendoGridCellTemplate let-dataItem>
                        {{dataItem[col.field] | dateFormat}}
                    </ng-template>

                    <ng-template *ngIf="col.filter === 'numeric' && pageName !== 'DynamicGridDesignMaintenance'" kendoGridCellTemplate let-dataItem>
                        <div [class]="col.class">
                            {{col.numberOnly ? dataItem[col.field] : dataItem[col.field] | numberFormat}}
                        </div>
                    </ng-template>

                    <ng-template *ngIf="col.filter === 'numeric' && pageName === 'DynamicGridDesignMaintenance'" kendoGridCellTemplate let-dataItem>
                        <div class="k-form-field text-center">
                            {{dataItem[col.field]}}
                        </div>
                    </ng-template>

                    <ng-template *ngIf="col.editor === 'combobox' || col.editor === 'dropdown'" kendoGridCellTemplate let-dataItem>
                        {{dataSourceItems.length | itemValue: [col, pageName, dataItem[col.field], dataSourceItems]}}
                    </ng-template>

                    <ng-template *ngIf="col.controlTypeId === controlTypeNames.Document" kendoGridCellTemplate let-dataItem>
                        <div class="text-center">
                            <a (click)="openDocumentDialog()">
                                <i class="fas fa-file-pdf image-icon clickable-icon cursor-pointer"></i>
                            </a>
                        </div>
                    </ng-template>

                    <ng-template *ngIf="col.editor === 'boolean'" kendoGridCellTemplate let-dataItem>
                        <div class="k-form-field text-center">
                            <input type="checkbox" class="k-checkbox {{col.field}}" id="{{dataItem.id}}" [(ngModel)]="dataItem[col.field]" [attr.disabled]="true" [style]="inputStyles[col.field]" />
                            <label class="k-checkbox-label" for="{{dataItem.id}}"></label>
                        </div>
                    </ng-template>

                    <!-- kendoGridEditTemplate -->

                    <ng-template *ngIf="col.custom === true" kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                        <ng-container *ngTemplateOutlet="columnCustomTemplates[col.field]; context:{ colField: col.field, dataItem: dataItem, rowIndex: rowIndex }">
                        </ng-container>
                    </ng-template>

                    <ng-template *ngIf="col.editor === 'combobox' && this.formGroup !== undefined && this.formGroup.get(col.field) !== null" kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                        <kendo-multiselect *ngIf="col?.optionAndValidation?.isMultiSelect"
                                           container="app-root"
                                           placement="top"
                                           [tooltip]="inputToolTips[col.field]"
                                           [adaptivePosition]="false"
                                           [title]="comboBoxTitles[col.field]"
                                           [data]="comboBoxData[col.field]"
                                           [class]="col.field"
                                           [textField]="col.textField"
                                           [valueField]="col.valueField"
                                           [valuePrimitive]="true"
                                           [formControl]="formGroup.get(col.field)"
                                           [filterable]="true"
                                           [allowCustom]="false"
                                           [style]="inputStyles[col.field]"
                                           [style.pointer-events]="'all'"
                                           [popupSettings]="{ width: 500 }"
                                           [placeholder]="l('SelectItem')"
                                           [virtual]="{ itemHeight: 28, pageSize: 10 }"
                                           (focus)="onFocusChange(col.field)"
                                           (blur)="onBlurChange(col.field)"
                                           (filterChange)="onFilterChange($event)"
                                           (valueChange)="onMultiSelectValueChange($event, col)">
                        </kendo-multiselect>
                        <kendo-combobox *ngIf="!col?.optionAndValidation?.isMultiSelect"
                                        container="app-root"
                                        placement="top"
                                        [tooltip]="inputToolTips[col.field]"
                                        [adaptivePosition]="false"
                                        [title]="comboBoxTitles[col.field]"
                                        [data]="comboBoxData[col.field]"
                                        [class]="col.field"
                                        [textField]="col.textField"
                                        [valueField]="col.valueField"
                                        [valuePrimitive]="true"
                                        [formControl]="formGroup.get(col.field)"
                                        [filterable]="true"
                                        [allowCustom]="false"
                                        [style]="inputStyles[col.field]"
                                        [style.pointer-events]="'all'"
                                        [popupSettings]="{ width: 500 }"
                                        [placeholder]="l('SelectItem')"
                                        [virtual]="{ itemHeight: 28, pageSize: 10 }"
                                        (focus)="onFocusChange(col.field)"
                                        (blur)="onBlurChange(col.field)"
                                        (filterChange)="onFilterChange($event)"
                                        (valueChange)="onComboBoxValueChange($event, col)">
                            <ng-template kendoComboBoxItemTemplate let-dataItem>
                                <span *ngIf="col.field === 'menuIcon'" [class]="dataItem['id']"></span>
                                <ng-container *ngIf="dataItem['id'].toString().includes('k-icon')">
                                    <div style="margin-left: 17px;">
                                        {{ "&nbsp;" + dataItem.name }}
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!dataItem['id'].toString().includes('k-icon')">
                                    {{ "&nbsp;" + dataItem.name }}
                                </ng-container>
                            </ng-template>
                        </kendo-combobox>
                    </ng-template>

                    <ng-template *ngIf="col.editor === 'dropdown' && this.formGroup !== undefined && this.formGroup.get(col.field) !== null" kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                        <kendo-dropdownlist container="app-root"
                                            placement="top"
                                            [tooltip]="inputToolTips[col.field]"
                                            [adaptivePosition]="false"
                                            [title]="comboBoxTitles[col.field]"
                                            [data]="comboBoxData[col.field]"
                                            [class]="col.field"
                                            [textField]="col.textField"
                                            [valueField]="col.valueField"
                                            [valuePrimitive]="true"
                                            [formControl]="formGroup.get(col.field)"
                                            [style]="inputStyles[col.field]"
                                            [style.pointer-events]="'all'"
                                            [defaultItem]="defaultItem"
                                            [virtual]="{ itemHeight: 28, pageSize: 10 }"
                                            (focus)="onFocusChange(col.field)"
                                            (blur)="onBlurChange(col.field)"
                                            (filterChange)="onFilterChange($event)"
                                            (valueChange)="onComboBoxValueChange($event, col)">
                        </kendo-dropdownlist>
                    </ng-template>

                    <ng-template *ngIf="col.editor === 'text' || col.filter === 'text'" kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                        <input kendoTextBox
                               container="app-root"
                               placement="top"
                               [tooltip]="inputToolTips[col.field]"
                               [adaptivePosition]="false"
                               [class]="col.field"
                               [formControl]="formGroup.get(col.field)"
                               [style]="inputStyles[col.field]"
                               [type]="col?.optionAndValidation?.isPassword ? 'password' : 'text'"
                               (focus)="onFocusChange(col.field)"
                               (blur)="onBlurChange(col.field)"
                               (input)="onTextBoxValueChange($event, col)" />
                    </ng-template>

                    <ng-template *ngIf="col.filter === 'numeric' || col.editor === 'numeric'" kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                        <kendo-numerictextbox container="app-root"
                                              placement="top"
                                              [tooltip]="inputToolTips[col.field]"
                                              [adaptivePosition]="false"
                                              [class]="col.field"
                                              [formControl]="formGroup.get(col.field)"
                                              [spinners]="false"
                                              [format]="col?.optionAndValidation?.format"
                                              [min]="col?.optionAndValidation?.minimumValue"
                                              [max]="col?.optionAndValidation?.maximumValue"
                                              [attr.disabled]="disableNumericTextBox(col.field) ? true : null"
                                              (valueChange)="handleNumericOrStringChange(col)"
                                              [style]="inputStyles[col.field]"
                                              (focus)="onFocusChange(col.field)"
                                              (blur)="onBlurChange(col.field)">
                        </kendo-numerictextbox>
                    </ng-template>

                    <ng-template *ngIf="col.filter === 'numeric' && allowEdits && this.formGroup !== undefined && this.formGroup.get(col.field) !== null" kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                        <kendo-numerictextbox container="app-root"
                                              placement="top"
                                              [tooltip]="inputToolTips[col.field]"
                                              [adaptivePosition]="false"
                                              [formControl]="formGroup.get(col.field)"
                                              [spinners]="false"
                                              [format]="{ style: 'currency', currency: 'USD', currencyDisplay: 'symbol' }"
                                              [attr.disabled]="disableNumericTextBox(col.field) ? true : null"
                                              (valueChange)="handleNumericOrStringChange(col)"
                                              [style]="inputStyles[col.field]"
                                              (focus)="onFocusChange(col.field)"
                                              (blur)="onBlurChange(col.field)">
                        </kendo-numerictextbox>
                    </ng-template>

                    <ng-template *ngIf="(col.filter === 'date' || col.editor === 'date') && allowEdits && (col.allowEdits || col.editable) && this.formGroup !== undefined && this.formGroup.get(col.field) !== null" kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                        <kendo-datepicker container="app-root"
                                          placement="top"
                                          [tooltip]="inputToolTips[col.field]"
                                          [adaptivePosition]="false"
                                          [formControl]="formGroup.get(col.field)"
                                          [format]="col.optionAndValidation?.mask"
                                          [style]="inputStyles[col.field]"
                                          (focus)="onFocusChange(col.field)"
                                          (blur)="onBlurChange(col.field)"
                                          (valueChange)="onDatePickerValueChange(col)">
                        </kendo-datepicker>
                    </ng-template>

                    <ng-template *ngIf="col.field === 'dateCreated' || col.field === 'dateModified'" kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                        <div [class]="col.class">
                            {{dataItem[col.field] | dateFormat}}
                        </div>
                    </ng-template>

                    <ng-template *ngIf="col.editor === 'boolean'" kendoGridEditTemplate let-dataItem>
                        <div class="k-form-field text-center">
                            <input type="checkbox" class="k-checkbox" id="{{dataItem.id}}" [indeterminate]="col.optionAndValidation?.hasIndeterminateOption" [formControl]="formGroup.get(col.field)" [style]="inputStyles[col.field]" (click)="onCheckBoxValueChange(col)" kendoCheckBox />
                            <label class="k-checkbox-label" for="{{dataItem.id}}"></label>
                        </div>
                    </ng-template>

                    <ng-template *ngIf="col.controlTypeId === controlTypeNames.Document" kendoGridEditTemplate let-dataItem>
                        <kendo-upload [saveUrl]="uploadSaveUrl"
                                      [removeUrl]="uploadRemoveUrl"
                                      [restrictions]="inputFileRestrictions[col.field]"
                                      #fileUpload>
                        </kendo-upload>
                    </ng-template>

                    <!-- kendoGridGroupHeaderTemplate -->
                    <ng-template kendoGridGroupHeaderTemplate let-value="value" let-dataItem>
                        <div [hidden]="hideGroupHeaderValues" [innerHtml]="appendGroupHeaders(col.field, value)"></div><span class="{{applyIconToGroup(col.field, value, col.editor)}}"></span>{{col.title}}: {{value | dateNumericStringFormat: [col.field, gridSettings.columnsConfig, comboBoxData]}}
                    </ng-template>

                    <!-- kendoGridGroupFooterTemplate -->
                    <ng-template *ngIf="col.filter === 'numeric'" kendoGridGroupFooterTemplate let-group="group" let-aggregates>
                        <div *ngIf="unhideGroupFooter(group)" [class]="col.class">{{sumItems(group, col.field, action, customAggregates, distinctAggregates, items)}}</div>
                    </ng-template>

                    <ng-template *ngIf="i === getFirstUnhiddenIndex(gridSettings) && hasNumericColumn" kendoGridGroupFooterTemplate let-group="group" let-aggregates>
                        <div *ngIf="unhideGroupFooter(group)">{{l("TOTAL")}} - {{group.value | dateNumericStringFormat: [group.field, gridSettings.columnsConfig]}}</div>
                    </ng-template>

                    <!-- kendoGridHeaderTemplate -->
                    <ng-template *ngIf="col.field === 'document'" kendoGridHeaderTemplate let-column>
                        <div [title]='column.title'><i class="fas fa-file column-header-icon"></i></div>
                    </ng-template>

                    <ng-template *ngIf="col.field === 'isSelected'" kendoGridHeaderTemplate let-column>
                        <div [title]='column.title'><i class="far fa-check-square column-header-icon"></i></div>
                    </ng-template>

                    <!-- kendoGridFooterTemplate -->
                    <ng-template *ngIf="col.filter === 'numeric' && hasGrandTotal" kendoGridFooterTemplate let-column="column">
                        <div [class]="col.class">{{numericGrandTotals[col.field]}}</div>
                    </ng-template>

                    <ng-template *ngIf="i === getFirstUnhiddenIndex(gridSettings) && hasNumericColumn && hasGrandTotal" kendoGridFooterTemplate let-column="column">
                        {{l("GRANDTOTAL")}}
                    </ng-template>

                </kendo-grid-column>

                <ng-template *ngIf="hasDetail" kendoGridDetailTemplate [kendoGridDetailTemplateShowIf]="showGridDetails" let-dataItem="dataItem">
                    <div *ngIf="errorInDetail === true" style="font-weight:900">
                        {{l('NoDetailsAvailable')}}
                    </div>

                    <div id="grids-and-forms" *ngIf="isTabstrip === false">

                        <!-- <dynamic-component  [gridStructures]="gridStructures"
                                [formStructures]="formStructures"
                                [pageName]="pageName">
            </dynamic-component> -->

                        <div *ngFor="let gridDetailGridComponent of gridDetailGridComponents; let i=index">
                            <div *ngIf="dataItem[gridDetailGridComponent.childFieldName] || true">
                                <br *ngIf="i > 0" />
                                <h5 [innerHtml]="l(gridDetailGridComponent.gridComponentLabelKey)"></h5>
                                <dynamic-grid #gridDetailsDynamicGrid
                                              [gridStructureDataSourceName]="gridDetailGridComponent.gridStructureDataSourceName"
                                              [gridDataDataSourceName]="gridDetailGridComponent.gridDataDataSourceName"
                                              [useParentComponentData]="gridDetailGridComponent.useParentComponentData"
                                              [parentItem]="dataItem"
                                              [items]="dataItem[gridDetailGridComponent.childFieldName]"
                                              [customTemplates]="customTemplates"
                                              [gridName]="gridDetailGridComponent.gridName"
                                              [pageName]="gridDetailGridComponent.pageName"
                                              [catalogId]="catalogId"
                                              [erpId]="erpId"
                                              [dataSourceItems]="dataSourceItems"
                                              [innerGridHeight]="385"
                                              [isChildGrid]="true"
                                              (onSelectEmitter)="onSelect($event)"
                                              (moveToParentEmitter)="moveToParent($event)"
                                              (deleteChildItemEmitter)="deleteChildItem($event)"
                                              (disableActionButtonEmitter)="disableActionButtonEmitted($event)">
                                </dynamic-grid>
                            </div>
                        </div>

                        <!-- <div *ngFor="let formComponent of formComponents; let i=index">
                <br />
                <dynamic-form   [formStructureDataSourceName]="formComponent.formStructureDataSourceName"
                                [formDataDataSourceName]="formComponent.formDataDataSourceName"
                                [useDefaultActionButton]="formComponent.useDefaultActionButton">
                </dynamic-form>
            </div> -->
                    </div>

                </ng-template>

                <ng-template kendoPagerTemplate let-total="total" let-totalPages="totalPages" let-currentPage="currentPage">
                    <ng-container *ngIf="gridSettings?.gridData?.total > gridSettings?.state?.take">
                        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                        <kendo-pager-numeric-buttons *ngIf="gridStructure?.componentSettings === undefined || !gridStructure?.componentSettings?.pagerType" [buttonCount]="10"></kendo-pager-numeric-buttons>
                        <kendo-pager-input *ngIf="gridStructure?.componentSettings !== undefined && gridStructure?.componentSettings?.pagerType"></kendo-pager-input>
                        <kendo-pager-next-buttons></kendo-pager-next-buttons>
                    </ng-container>
                    <kendo-pager-page-sizes [pageSizes]="[5, 10, 25, 50, 100, 250]"></kendo-pager-page-sizes>
                    <div class="k-pager-info k-label">{{ retrieveMinItemLength(currentPage, gridSettings?.state.take, total) }} - {{ retrieveMaxItemLength(currentPage, gridSettings?.state.take, total) }} of {{ applyFilteredText(items, total) }} {{ grid.view.total | number }} items</div>
                    <custom-kendo-pager-info [filteredTotal]="total" [unfilteredTotal]="items != undefined ? items.length : 0"></custom-kendo-pager-info>
                </ng-template>

                <kendo-grid-excel *ngIf="hasExportToExcel" fileName="{{fileName}}" [fetchData]="allData">
                    <kendo-excelexport-column *ngFor="let col of filterColumnConfigs(); let i=index"
                                              [field]="col.field"
                                              [title]="col.title"
                                              [width]="col.width"
                                              [hidden]="col.hidden">

                        <ng-template kendoExcelExportGroupHeaderTemplate let-value="value" let-dataItem>
                            {{col.title}}: {{value | dateNumericStringFormat: [col.field, gridSettings.columnsConfig]}}
                        </ng-template>

                        <ng-template *ngIf="col.filter === 'numeric'" kendoExcelExportGroupFooterTemplate let-group="group" let-aggregates>
                            <div [hidden]="unhideGroupFooter(group)" [class]="col.class">{{sumItems(group, col.field, action, customAggregates, distinctAggregates, items)}}</div>
                        </ng-template>

                        <ng-template *ngIf="i === getFirstUnhiddenIndex(gridSettings) && hasNumericColumn" kendoExcelExportGroupFooterTemplate let-group="group" let-aggregates>
                            <div [hidden]="unhideGroupFooter(group)">{{l("TOTAL")}} - {{group.value | dateNumericStringFormat: [group.field, gridSettings.columnsConfig]}}</div>
                        </ng-template>

                        <ng-template *ngIf="i === getFirstUnhiddenIndex(gridSettings) && hasNumericColumn" kendoExcelExportFooterTemplate let-column="column">
                            {{l("GRANDTOTAL")}}
                        </ng-template>

                        <ng-template *ngIf="col.filter === 'numeric'" kendoExcelExportFooterTemplate let-column="column">
                            <div [class]="col.class">{{getGrandTotal(col.field)}}</div>
                        </ng-template>

                    </kendo-excelexport-column>

                </kendo-grid-excel>

            </kendo-grid>

            <grid-context-menu [for]="grid" [menuItems]="gridContextMenuItems" (select)="onSelect($event)">
            </grid-context-menu>

        </kendo-splitter-pane>

        <kendo-splitter-pane id="k-s-p-pdf-viewer" [resizable]="resizablePdf" *ngIf="isPdfViewerVisible" size="'40%'" min="40" [scrollable]="isPdfViewerScrollable">
            <sls-pdf-viewer #slsPdfviewer [pdfSrc]="pdfSrc" [itemName]="pdfName" (onClose)="closePdfViewer()" (onOpenPdf)="closePdfViewer()"></sls-pdf-viewer>
        </kendo-splitter-pane>
    </kendo-splitter>
</div>