import { AppComponentBase } from "@shared/common/app-component-base";
import { Component, Injector, Output, EventEmitter, Input, HostListener } from "@angular/core";
import { LocationDto } from "@shared/service-proxies/service-proxies";

@Component({
    selector: 'export-to-excel',
    templateUrl: './export-to-excel.component.html',
    styleUrls: ['./export-to-excel.component.scss']
})

export class ExportToExcelComponent extends AppComponentBase {
    @Input() action: string;
    @Input() locations: LocationDto[] = [];
    @Input() selectedLocation: LocationDto;
    @Input() hasUnsavedChanges: boolean = false;
    @Input() canEdit: boolean = false;
    @Output() exportToExcel: EventEmitter<any> = new EventEmitter<any>();
    @Output() updateDataHandler: EventEmitter<any> = new EventEmitter<any>();
    @Output() openUnsavedChanges: EventEmitter<boolean> = new EventEmitter<boolean>();

    exportTitle: string = this.l("ExportToExcel");
    longestLocationRetrieved: boolean = false;
    longestClientRetrieved: boolean = false;
    clientStyleApplied: boolean = false;
    locationStyleApplied: boolean = false;
    exportStyleApplied: boolean = false;
    locationWidth: number = 54;
    clientWidth: number = 54;
    listHeight: number = 0;

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.computeComboboxListHeight();
    }

    ngOnInit(): void {
        this.computeComboboxListHeight();
    }

    computeComboboxListHeight(): void {
        const listHeight = window.innerHeight - 250;
        this.listHeight = listHeight < 80 ? 80 : listHeight;
    }

    ngAfterViewChecked(): void {
        let exportExcelButton = document.getElementById("export-excel");
        let locationComboBox = this.canEdit ? document.getElementById("location-combo-box-edit") : document.getElementById("location-combo-box");
        let clientComboBox = this.canEdit ? document.getElementById("client-combo-box-edit") : document.getElementById("client-combo-box");

        if (this.locations.length >= 1 && this.longestLocationRetrieved == false) {
            this.locationWidth = 54;
            var longestLocation = this.locations[0].locationName;

            for (var i = 1; i < this.locations.length; i++) {
                if (this.locations[i].locationName.length > longestLocation.length) {
                    longestLocation = this.locations[i].locationName;
                }
            }

            this.longestLocationRetrieved = true;
            for (var j = 0; j < longestLocation.length; j++) {
                if (longestLocation[j] == ' ') {
                    this.locationWidth += 4;
                }
                else {
                    this.locationWidth += 8;
                }
            }

            if (locationComboBox != undefined) {
                locationComboBox.setAttribute("style", "width:" + this.locationWidth + "px");
            }

            this.exportStyleApplied = false;
            this.locationStyleApplied = false;
            this.clientStyleApplied = false;
        }

        if (this.longestClientRetrieved == false) {
            this.longestClientRetrieved = true;

            clientComboBox?.setAttribute("style", "width:" + this.clientWidth + "px");

            this.exportStyleApplied = false;
            this.locationStyleApplied = false;
            this.clientStyleApplied = false;
        }

        if (locationComboBox !== undefined && locationComboBox !== null && this.locationStyleApplied === false) {
            locationComboBox.setAttribute("style", "width:" + this.locationWidth + "px");
            this.locationStyleApplied = true;
        } else {
            this.locationStyleApplied = true;
        }

        if (clientComboBox !== undefined && clientComboBox !== null && this.clientStyleApplied === false) {
            clientComboBox.setAttribute("style", "right:" + (locationComboBox.clientWidth + 20) + "px;width:" + this.clientWidth + "px");
            this.clientStyleApplied = true;
        } else {
            this.clientStyleApplied = true;
        }
        if (exportExcelButton !== undefined && exportExcelButton !== null && this.exportStyleApplied === false) {
            let clientComboWidth = clientComboBox !== undefined && clientComboBox !== null ? clientComboBox.clientWidth : 0;
            let locationComboWidth = locationComboBox !== undefined && locationComboBox !== null ? locationComboBox.clientWidth : 0;
            let exportPositionWidth = clientComboWidth + locationComboWidth + 25;
            exportExcelButton.setAttribute("style", "right:" + exportPositionWidth + "px");
            this.exportStyleApplied = true;
        } else {
            this.exportStyleApplied = true;
        }
    }

    openUnsavedChangesForFilter(e): void {
        if (this.hasUnsavedChanges) {
            this.openUnsavedChanges.emit(e);
        }
    }

    exportGrid(): void {
        this.exportToExcel.emit();
    }

    clientChange(comboBoxControl = null): void {
        if (comboBoxControl !== null && comboBoxControl.dataItem !== undefined) {
            comboBoxControl.dataItem = comboBoxControl._previousDataItem;
            comboBoxControl._text = comboBoxControl._previousDataItem.clientName;
        }

        this.longestLocationRetrieved = false;
        this.clientStyleApplied = false;
    }

    locationChange(comboBoxControl = null): void {
        if (comboBoxControl !== null && comboBoxControl.dataItem !== undefined) {
            comboBoxControl.dataItem = comboBoxControl._previousDataItem;
            comboBoxControl._text = comboBoxControl._previousDataItem.locationName;
            this.selectedLocation = comboBoxControl.dataItem;
        }
    }
}