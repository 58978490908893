import { Component, Input, forwardRef, Output, EventEmitter, Injector, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { PDFProgressData } from 'ng2-pdf-viewer';
import { saveAs } from '@progress/kendo-file-saver';
import { round as _round } from 'lodash-es';
import { ToolBarToolComponent } from '@progress/kendo-angular-toolbar';

@Component({
    providers: [{ provide: { NG_VALUE_ACCESSOR, ToolBarToolComponent }, useExisting: forwardRef(() => DynamicPdfAndImageViewer) }],
    selector: 'dynamic-pdf-and-image-viewer',
    templateUrl: './dynamic-pdf-and-image-viewer.component.html',
    styleUrls: ['./dynamic-pdf-and-image-viewer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicPdfAndImageViewer extends AppComponentBase {
    @Input() public attachmentsIsPdf: boolean = true;
    @Input() public attachmentsIsPreview: boolean = false;
    @Input() public pdfSrc: string = "";
    @Input() public itemName: string = "";
    @Input() public itemHeader: string = "";
    @Input() public width: string = "auto";
    @Input() public height: string = "auto";
    @Input() public hasToolbar: boolean = true;
    @Input() public hasFirstPageButton: boolean = true;
    @Input() public hasPreviousPageButton: boolean = true;
    @Input() public hasNextPageButton: boolean = true;
    @Input() public hasLastPageButton: boolean = true;
    @Input() public hasZoomOutButton: boolean = true;
    @Input() public hasResetZoomButton: boolean = true;
    @Input() public hasZoomInButton: boolean = true;
    @Input() public hasRotateClockWiseButton: boolean = true;
    @Input() public hasDownloadButton: boolean = true;
    @Input() public hasOpenInNewWindowButton: boolean = true;
    @Input() public hasCloseButton: boolean = true;

    page: number = 0;
    totalPage: number = 0;
    zoom: number = 0.6;
    step: number = 0;

    constructor(
        injector: Injector,
        private cd: ChangeDetectorRef) {
        super(injector);
    }

    ngOnInit(): void {
        this.configureSettings();
    }

    ngOnChanges(): void {
        this.configureSettings();
    }

    configureSettings(): void {
        if (this.attachmentsIsPreview) {
            if (this.attachmentsIsPdf) {
                this.hasFirstPageButton = false;
                this.hasPreviousPageButton = true;
                this.hasNextPageButton = true;
                this.hasLastPageButton = false;
                this.hasZoomOutButton = false;
                this.hasResetZoomButton = false;
                this.hasZoomInButton = false;
                this.hasRotateClockWiseButton = false;
                this.hasDownloadButton = false;
                this.hasOpenInNewWindowButton = false;
                this.hasCloseButton = false;
            }
            else {
                this.hasToolbar = false;
            }
        }
        else {
            if (this.attachmentsIsPdf) {
                this.hasFirstPageButton = true;
                this.hasPreviousPageButton = true;
                this.hasNextPageButton = true;
                this.hasLastPageButton = true;
                this.hasZoomOutButton = true;
                this.hasResetZoomButton = true;
                this.hasZoomInButton = true;
                this.hasRotateClockWiseButton = true;
                this.hasDownloadButton = true;
                this.hasOpenInNewWindowButton = true;
                this.hasCloseButton = true;
            }
            else {
                this.totalPage = 1;
                this.hasFirstPageButton = false;
                this.hasPreviousPageButton = false;
                this.hasNextPageButton = false;
                this.hasLastPageButton = false;
                this.hasZoomOutButton = false;
                this.hasResetZoomButton = false;
                this.hasZoomInButton = false;
                this.hasRotateClockWiseButton = false;
                this.hasDownloadButton = true;
                this.hasOpenInNewWindowButton = true;
                this.hasCloseButton = true;
            }
        }
    }

    @Output() onProgress: EventEmitter<any> = new EventEmitter();
    pdfLoading(progressData: PDFProgressData): void {
        this.onProgress.emit();
    }

    @Output() onClose: EventEmitter<any> = new EventEmitter();
    closePdfViewer(): void {
        this.onClose.emit();
    }

    @Output() onOpenPdf: EventEmitter<any> = new EventEmitter();
    openPdfNewTab(): void {
        const width = this.attachmentsIsPdf ? "width: 100%;" : "width: auto;";
        window.open().document.body.innerHTML = '<html><head><title>STREAMLINEA - ' + this.itemName + '</title></head><body><embed src="' + this.pdfSrc + '" style="' + width + 'height: 100%;" data="' + this.itemName + '"/></body></html>';
        this.onOpenPdf.emit();
    }

    afterLoadComplete(pdfData: any): void {
        this.totalPage = pdfData.numPages;
        this.zoom = 1;

        setTimeout(() => {
            this.gridIsLoading(false);
        }, 1500);

        this.cd.markForCheck();
    }

    pageRendered(e) {
        if (this.page === 0) {
            this.page = 1;
        }
    }

    downloadPdf(): void {
        saveAs(this.pdfSrc, this.itemName);
    }

    firstPage(): void {
        this.page = 1;
    }

    nextPage(): void {
        this.page++;

        if (this.page > this.totalPage) {
            this.page = 1;
        }
    }

    prevPage(): void {
        this.page--;
    }

    lastPage(): void {
        this.page = this.totalPage;
    }

    zoomIn(): void {
        if (this.zoom < 5) {
            this.zoom = _round(this.zoom + 0.1, 1);
        }
    }

    resetZoom(): void {
        if (this.zoom !== 1) {
            this.zoom = 1;
        }
    }

    zoomOut(): void {
        if (this.zoom > 0.6) {
            this.zoom = _round(this.zoom - 0.1, 1);
        }
    }

    rotateClockwise(): void {
        if (this.step === 270) {
            this.step = 0;
        } else {
            this.step += 90;
        }
    }
}
