import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DynamicDataServiceProxy, RetrieveDynamicDataRequest } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'dynamic-generate-password-button',
    templateUrl: './dynamic-generate-password-button.component.html',
    styleUrls: ['./dynamic-generate-password-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicGeneratePasswordButtonComponent extends AppComponentBase {

    @Input() actionButtonStructure: any;
    @Input() dynamicFormComponents: any;
    renderingPassword: boolean = false;
    generatedPassword: string = "";
    passwordNotGenerated: boolean = true;
    disablePasswordGenerationButton: boolean = false;

    constructor(
        injector: Injector,
        private cd: ChangeDetectorRef,
        private _dynamicDataServiceProxy: DynamicDataServiceProxy
    ) {
        super(injector);
    }

    generatePassword() {
        this.renderingPassword = true;
        let request = new RetrieveDynamicDataRequest();
        request.dataSourceName = "GeneratePassword";
        request.data = {};

        if (this.dynamicFormComponents[0].formContents !== undefined) {
            request.data["userId"] = this.dynamicFormComponents[0].formContents.id;
        }

        this._dynamicDataServiceProxy.retrieveDynamicData(request)
            .pipe(
                finalize(() => {
                    this.renderingPassword = false;
                    this.cd.markForCheck();
                })
            ).subscribe(result => {
                if (result.data.items[0].isSuccess) {
                    this.generatedPassword = result.data.items[0].password;
                    this.passwordNotGenerated = false;
                    this.disablePasswordGenerationButton = true;
                }
                else {
                    let displayError = "";
                    result.data.items[0].validationMessage.forEach(error => {
                        displayError += error + "<br><br>";
                    });

                    this.message.error(displayError, this.l('GenerateTemporaryPassword'), { isHtml: true });
                }
            });
    }
}
