import { Pipe, PipeTransform } from '@angular/core';
import { AppComponentBase } from '../../../shared/common/app-component-base';

@Pipe({
    name: 'itemValue'
})
export class ItemValuePipe extends AppComponentBase implements PipeTransform {

    transform(dataSourceItemsCount: any, args: any[]): string {
        let col = args[0];
        let pageName = args[1];
        let dataItem = args[2];
        let dataSourceItems = args[3];
        if (col.dynamicGridColumnId === undefined && col.dataSourceName === undefined) {
            return dataItem;
        }
        else {
            const isDynamicPages = pageName === "";
            const dataSourceName = isDynamicPages ? (col.dynamicGridColumnId ? col.dynamicGridColumnId : col.dataSourceName) : col.dataSourceName;

            if (Array.isArray(dataItem)) {
                var result = "";
                for (let i = 0; i < dataItem.length; i++) {
                    if (dataSourceItems && dataSourceItems.length > 0) {
                        const dataSource = dataSourceItems.filter(x => x.dataSourceName === dataSourceName && x.dataSourceItems?.length > 0)
                            .sort((x, y) => y.dataSourceItems.length - x.dataSourceItems.length);

                        if (dataSource.length > 0) {
                            const item = dataSource[0]?.dataSourceItems.filter(y => y[col.valueField] === dataItem[i]);

                            if (item.length > 0) {
                                result += item[0]?.[col.textField];
                            }
                            else {
                                result += dataItem[i];
                            }
                        }
                    }

                    if (i + 1 < dataItem.length) {
                        result += ", ";
                    }
                }

                return result;
            }
            else {
                let name = "";

                if (dataSourceItems && dataSourceItems.length > 0) {
                    const dataSource = dataSourceItems.filter(x => x.dataSourceName === dataSourceName && x.dataSourceItems?.length > 0)
                        .sort((x, y) => y.dataSourceItems.length - x.dataSourceItems.length);

                    if (dataSource.length > 0) {
                        const item = dataSource[0]?.dataSourceItems.filter(y => y[col.valueField] === dataItem);

                        if (item.length > 0) {
                            name = item[0]?.[col.textField];
                        }
                        else {
                            return dataItem;
                        }
                    }
                }

                return name;
            }
        }
    }

}