<span id="client-grid-filter-label">{{l("ClientColon")}}</span>
<kendo-combobox #clientComboBox
                [data]="clients"
                [allowCustom]="false"
                [clearButton]="false"
                [textField]="'name'"
                [valueField]="'id'"
                [value]="selectedClient"
                (valueChange)="clientChange(clientComboBox)"
                id="client-combobox"
                [listHeight]="listHeight"
                placeholder="{{l('SelectClient')}}">
</kendo-combobox>

<span id="erp-grid-filter-label">{{l("ErpColon")}}</span>
<kendo-combobox #erpComboBox
                [data]="erps"
                [allowCustom]="false"
                [clearButton]="false"
                [textField]="'name'"
                [valueField]="'id'"
                [value]="selectedErp"
                (valueChange)="erpChange(erpComboBox)"
                id="erp-combobox"
                [listHeight]="listHeight"
                placeholder="{{l('SelectErp')}}">
</kendo-combobox>