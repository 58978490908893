{{l("ClientColon")}}
<kendo-combobox
    #clientComboBox
    [data]="clients"
    [allowCustom]="false"
    [clearButton]="false"
    [textField]="'name'"
    [valueField]="'id'"
    [value]="selectedClient"
    (valueChange)="clientChange(clientComboBox)"
    id="client-combobox-data-filter"
    [listHeight]="listHeight"
    placeholder="{{l('SelectClient')}}">
</kendo-combobox>
{{l("ErpColon")}}
<kendo-combobox
    #erpComboBox
    [data]="erps"
    [allowCustom]="false"
    [clearButton]="false"
    [textField]="'name'"
    [valueField]="'id'"
    [value]="selectedErp"
    (valueChange)="erpChange(erpComboBox)"
    id="erp-combobox-data-filter"
    [listHeight]="listHeight"
    placeholder="{{l('SelectErp')}}">
</kendo-combobox>