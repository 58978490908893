import { Component, Injector, ViewEncapsulation, Output, EventEmitter, Input, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { merge as _merge } from 'lodash-es';
import { AppConsts } from '@shared/AppConsts';
import { DynamicGridComponent } from '@app/shared/common/dynamic-components/dynamic-grid/dynamic-grid.component';
import { DynamicFormComponent } from '@app/shared/common/dynamic-components/dynamic-form/dynamic-form.component';
import { CustomTemplate } from '@app/shared/common/dynamic-components/dynamic-grid/custom-template';
import { DynamicDataServiceProxy, SaveDynamicDataRequest } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { ControlTypeNames } from '@shared/AppEnums';

@Component({
    selector: 'dynamicPageModal',
    templateUrl: './dynamic-page-modal.component.html',
    styleUrls: ['./dynamic-page-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicPageModalComponent extends AppComponentBase {
    @ViewChild(DynamicGridComponent) dynamicGridComponent: DynamicGridComponent;
    @ViewChild(DynamicFormComponent) dynamicFormComponent: DynamicFormComponent;

    @Input() modalGridComponents: any[] = [];
    @Input() modalFormComponents: any[] = [];
    @Input() gridStructureDataSourceName: string;
    @Input() gridDataDataSourceName: string;
    @Input() formStructureDataSourceName: string;
    @Input() formDataDataSourceName: string;
    @Input() previewGridSettings: any = undefined;
    @Input() usePreviewGrid: boolean = false;
    @Input() actionType = AppConsts.actionType.read;
    @Input() parentItem: any;
    @Input() pageName: string;
    @Input() gridStructure: any;
    @Input() formStructure: any;
    @Input() gridStructures: any;
    @Input() formStructures: any;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    readonly controlTypeNames: typeof ControlTypeNames = ControlTypeNames;

    active = false;
    saving = false;
    gridComponents: any[] = [];
    formComponents: any[] = [];
    retrieveDataBy: string;

    customTemplates: CustomTemplate[] = [];

    constructor(
        injector: Injector,
        private _dynamicDataServiceProxy: DynamicDataServiceProxy,
        private cd: ChangeDetectorRef
    ) {
        super(injector);
    }

    ngOnInit(): void {
        if (this.actionType === AppConsts.actionType.update) {
        } else {
        }

        this.getDynamicStructure();

        this.cd.markForCheck();
    }

    getDynamicStructure() {
        this._dynamicDataServiceProxy.retrieveDynamicStructure(this.pageName, "0")
            .pipe(
                finalize(() => {
                    this.gridIsLoading(false);
                    this.cd.markForCheck();
                })
            ).subscribe(result => {
                if (result.data !== null && result.data.items.length > 0) {
                    result.data.items.forEach(item => {
                        item = this.formatItemDate(item);
                    });

                    this.gridComponents = result.data.items[0]?.attachedDynamicGridComponents;
                    this.formComponents = result.data.items[0]?.attachedDynamicFormComponents;

                    this.gridStructures = result.data.items[0]?.attachedDynamicGridStructures;
                    this.formStructures = result.data.items[0]?.attachedDynamicFormStructures;

                    // this.gridStructure = {
                    //     items: result.data.items[0]?.attachedDynamicGridStructures[0]?.items,
                    //     componentSettings: result.data.items[0]?.attachedDynamicGridStructures[0]?.componentSettings
                    // };

                    // this.formStructure = {
                    //     items: result.data.items[0]?.attachedDynamicFormStructures[0]?.items,
                    //     componentSettings: result.data.items[0]?.attachedDynamicFormStructures[0]?.componentSettings
                    // };
                }
            });
    }

    addCustomTemplates() {
        this.cd.markForCheck();
    }

    ngAfterViewInit() {
        this.addCustomTemplates();
    }

    save(): void {
        if (this.parentItem !== undefined &&
            (this.parentItem.action === this.l("CloneGrid") ||
                this.parentItem.action === this.l("CloneForm") ||
                this.parentItem.action === this.l("CloneActionButton") ||
                this.parentItem.action === this.l("ClonePage") ||
                this.parentItem.action === this.l("CloneMenu"))) {

            let request = new SaveDynamicDataRequest();
            let data = {};
            data['id'] = this.parentItem.dataItem.id;

            let fieldNameForValidationMessage = "";

            if (this.parentItem.action === this.l("CloneGrid")) {
                data['gridName'] = this.dynamicFormComponent.dynamicForm.value.gridName;
                request.dataSourceName = "GridDataDynamicGridMaintenance";
                fieldNameForValidationMessage = this.l("DynamicGridName");
            }
            else if (this.parentItem.action === this.l("CloneForm")) {
                data['formName'] = this.dynamicFormComponent.dynamicForm.value.formName;
                request.dataSourceName = "GridDataDynamicFormMaintenance";
                fieldNameForValidationMessage = this.l("DynamicFormName");
            }
            else if (this.parentItem.action === this.l("CloneActionButton")) {
                data['actionButtonName'] = this.dynamicFormComponent.dynamicForm.value.actionButtonName;
                request.dataSourceName = "GridDataDynamicActionButtonMaintenance";
                fieldNameForValidationMessage = this.l("DynamicActionButtonName");
            }
            else if (this.parentItem.action === this.l("ClonePage")) {
                data['pageName'] = this.dynamicFormComponent.dynamicForm.value.pageName;
                request.dataSourceName = "GridDataDynamicPageMaintenance";
                fieldNameForValidationMessage = this.l("DynamicPageName");
            }
            else if (this.parentItem.action === this.l("CloneMenu")) {
                data['menuName'] = this.dynamicFormComponent.dynamicForm.value.menuName;
                request.dataSourceName = "GridDataDynamicNavigationMaintenance";
                fieldNameForValidationMessage = this.l("DynamicMenuName");
            }

            if (this.dynamicFormComponent.dynamicForm.status === "INVALID") {
                this.notify.warn(this.l("PleaseEnterCloneName", fieldNameForValidationMessage));
                return;
            }

            request.action = "clone";
            request.data = data;

            this.gridIsLoading(true);
            this._dynamicDataServiceProxy.saveDynamicData(request)
                .pipe(
                    finalize(() => {
                        this.cd.markForCheck();
                    })
                ).subscribe(result => {
                    if (result.data !== null) {
                        this.gridIsLoading(false);
                        this.modalSave.emit({ isSuccess: true, clonedItem: this.formatItemDate(result.data) });
                    }
                });
        }
        else if (this.parentItem !== undefined && this.parentItem.action === this.l("GridControlTypeOptionAndValidation")) {
            const controlTypeId = this.parentItem.dataItem.controlTypeId;

            var data = {};
            if (this.dynamicFormComponent) {
                var value = this.dynamicFormComponent.dynamicForm.value;
                var formValue = {};
                for (var key in value) {
                    if (value.hasOwnProperty(key)) {
                        formValue[key] = value[key];
                    }
                }
            }

            if (controlTypeId === this.controlTypeNames.TextBox/*GridInput*/) {
                let type = this.parentItem.dataItem.controlType.textBoxType;

                type.options.isRequired = formValue["isRequired"];
                type.options.syncedWithErp = formValue["syncedWithErp"];
                type.options.feedbackMessage = formValue["feedbackMessage"] ?? "";
                type.isPassword = formValue["isPassword"];
                type.passwordMatch = formValue["passwordMatch"];
                type.regexValidation = formValue["regexValidation"];
                type.maximumLength = formValue["maximumLength"];
                type.minimumLength = formValue["minimumLength"];
                type.width = 0;
                type.fieldType = "input";
            }
            else if (controlTypeId === this.controlTypeNames.Numeric/*GridNumeric*/) {
                let type = this.parentItem.dataItem.controlType.numericType;

                type.options.isRequired = formValue["isRequired"];
                type.options.syncedWithErp = formValue["syncedWithErp"];
                type.options.feedbackMessage = formValue["feedbackMessage"] ?? "";
                type.maximumLength = formValue["maximumLength"];
                type.minimumLength = formValue["minimumLength"];
                type.fieldType = "numeric";
            }
            else if (controlTypeId === this.controlTypeNames.Date/*GridDate*/) {
                let type = this.parentItem.dataItem.controlType.dateType;

                type.options.isRequired = formValue["isRequired"];
                type.options.syncedWithErp = formValue["syncedWithErp"];
                type.options.feedbackMessage = formValue["feedbackMessage"] ?? "";
                type.mask = formValue["mask"];
                type.defaultDate = formValue["defaultDate"];

                type.dateGrid.forEach(item => {
                    item = this.formatItemDate(item);
                });
            }
            else if (controlTypeId === this.controlTypeNames.ComboBox/*GridCombobox*/) {
                let type = this.parentItem.dataItem.controlType.comboBoxType;

                type.options.isRequired = formValue["isRequired"];
                type.options.syncedWithErp = formValue["syncedWithErp"];
                type.options.feedbackMessage = formValue["feedbackMessage"] ?? "";
                type.isAutoComplete = formValue["isAutoComplete"];
                type.isMultiSelect = formValue["isMultiSelect"];
                type.dataDependency = formValue["dataDependency"];
                type.dependentColumnName = formValue["dependentColumnName"];
            }
            else if (controlTypeId === this.controlTypeNames.DropDown/*GridDropdown*/) {
                let type = this.parentItem.dataItem.controlType.dropdownType;

                type.options.isRequired = formValue["isRequired"];
                type.options.syncedWithErp = formValue["syncedWithErp"];
                type.options.feedbackMessage = formValue["feedbackMessage"] ?? "";
                type.dataDependency = formValue["dataDependency"];
                type.dependentColumnName = formValue["dependentColumnName"];
            }
            else if (controlTypeId === this.controlTypeNames.CheckBox/*GridCheckbox*/) {
                let type = this.parentItem.dataItem.controlType.checkboxType;

                type.options.isRequired = formValue["isRequired"];
                type.options.syncedWithErp = formValue["syncedWithErp"];
                type.options.feedbackMessage = formValue["feedbackMessage"] ?? "";
                type.indeterminateOption = formValue["indeterminateOption"];
                type.setDefault = formValue["setDefault"];
            }
            else if (controlTypeId === this.controlTypeNames.DisplayImage/*GridDocumentDisplayImageLogo*/) {
                let type = this.parentItem.dataItem.controlType.displayImageType;

                type.imageName = formValue["imageName"];
            }

            this.modalSave.emit({ isSuccess: true, parentId: this.parentItem.dataItem });
        }
        else if (this.parentItem !== undefined && this.parentItem.action === this.l("FormControlTypeOptionAndValidation")) {
            const controlTypeId = this.parentItem.dataItem.controlTypeId;

            var data = {};
            if (this.dynamicFormComponent) {
                var value = this.dynamicFormComponent.dynamicForm.value;
                var formValue = {};
                for (var key in value) {
                    if (value.hasOwnProperty(key)) {
                        formValue[key] = value[key];
                    }
                }
            }

            if (controlTypeId === this.controlTypeNames.TextBox/*FormInput*/) {
                let type = this.parentItem.dataItem.controlType.textBoxType;

                type.options.isRequired = formValue["isRequired"];
                type.options.syncedWithErp = formValue["syncedWithErp"];
                type.options.feedbackMessage = formValue["feedbackMessage"] ?? "";
                type.isPassword = formValue["isPassword"];
                type.passwordMatch = formValue["passwordMatch"];
                type.regexValidation = formValue["regexValidation"];
                type.maximumLength = formValue["maximumLength"];
                type.minimumLength = formValue["minimumLength"];
                type.width = formValue["width"];
                type.fieldType = "input";
            }
            else if (controlTypeId === this.controlTypeNames.TextArea/*FormTextArea*/) {
                let type = this.parentItem.dataItem.controlType.textAreaType;

                type.options.isRequired = formValue["isRequired"];
                type.options.syncedWithErp = formValue["syncedWithErp"];
                type.options.feedbackMessage = formValue["feedbackMessage"] ?? "";
                type.maximumLength = formValue["maximumLength"];
                type.minimumLength = formValue["minimumLength"];
                type.rows = formValue["rows"];
                type.columns = formValue["columns"];
                type.resizeOption = formValue["resizeOption"];
            }
            else if (controlTypeId === this.controlTypeNames.Numeric/*FormNumeric*/) {
                let type = this.parentItem.dataItem.controlType.numericType;

                type.options.isRequired = formValue["isRequired"];
                type.options.syncedWithErp = formValue["syncedWithErp"];
                type.options.feedbackMessage = formValue["feedbackMessage"] ?? "";
                type.maximumLength = formValue["maximumLength"];
                type.minimumLength = formValue["minimumLength"];
                type.fieldType = "numeric";
            }
            else if (controlTypeId === this.controlTypeNames.Date/*FormDate*/) {
                let type = this.parentItem.dataItem.controlType.dateType;

                type.options.isRequired = formValue["isRequired"];
                type.options.syncedWithErp = formValue["syncedWithErp"];
                type.options.feedbackMessage = formValue["feedbackMessage"] ?? "";
                type.mask = formValue["mask"];
                type.defaultDate = formValue["defaultDate"];
            }
            else if (controlTypeId === this.controlTypeNames.ComboBox/*FormCombobox*/) {
                let type = this.parentItem.dataItem.controlType.comboBoxType;

                type.options.isRequired = formValue["isRequired"];
                type.options.syncedWithErp = formValue["syncedWithErp"];
                type.options.feedbackMessage = formValue["feedbackMessage"] ?? "";
                type.isAutoComplete = formValue["isAutoComplete"];
                type.isMultiSelect = formValue["isMultiSelect"];
                type.dataDependency = formValue["dataDependency"];
                type.dependentColumnName = formValue["dependentColumnName"];
            }
            else if (controlTypeId === this.controlTypeNames.DropDown/*FormDropdown*/) {
                let type = this.parentItem.dataItem.controlType.dropdownType;

                type.options.isRequired = formValue["isRequired"];
                type.options.syncedWithErp = formValue["syncedWithErp"];
                type.options.feedbackMessage = formValue["feedbackMessage"] ?? "";
                type.dataDependency = formValue["dataDependency"];
                type.dependentColumnName = formValue["dependentColumnName"];
            }
            else if (controlTypeId === this.controlTypeNames.CheckBox/*FormCheckbox*/) {
                let type = this.parentItem.dataItem.controlType.checkboxType;

                type.options.isRequired = formValue["isRequired"];
                type.options.syncedWithErp = formValue["syncedWithErp"];
                type.options.feedbackMessage = formValue["feedbackMessage"] ?? "";
                type.indeterminateOption = formValue["indeterminateOption"];
                type.setDefault = formValue["setDefault"];
                type.rightSidedLabel = formValue["rightSidedLabel"];
            }
            else if (controlTypeId === this.controlTypeNames.RadioButton/*FormRadioButton*/) {
                let type = this.parentItem.dataItem.controlType.radioButtonType;

                type.options.isRequired = formValue["isRequired"];
                type.options.syncedWithErp = formValue["syncedWithErp"];
                type.options.feedbackMessage = formValue["feedbackMessage"] ?? "";
                type.setDefault = formValue["setDefault"];
                type.choices = this.dynamicGridComponent.items;
            }
            else if (controlTypeId === this.controlTypeNames.FileUpload/*FormFileUpload*/) {
                let type = this.parentItem.dataItem.controlType.fileUploadType;

                type.documentType = formValue["documentType"];
                type.fileSize = formValue["fileSize"];
            }
            else if (controlTypeId === this.controlTypeNames.DisplayImage/*FormDisplayImageLogo*/) {
                let type = this.parentItem.dataItem.controlType.displayImageType;

                type.imageName = formValue["imageName"];
            }
            else if (controlTypeId === this.controlTypeNames.InformationOnly/*FormInformationOnly*/) {
                let type = this.parentItem.dataItem.controlType.informationType;

                type.dataDependency = formValue["dataDependency"];
                type.dependentColumnName = formValue["dependentColumnName"];
                type.textDisplayOption = formValue["textDisplayOption"];
            }
            else if (controlTypeId === this.controlTypeNames.StaticText/*FormStaticText*/) {
                let type = this.parentItem.dataItem.controlType.staticType;

                type.labelKey = formValue["labelKey"];
                type.link = formValue["link"];
                type.textDisplayOption = formValue["textDisplayOption"];
            }
            else if (controlTypeId === this.controlTypeNames.Spacer/*FormSpacer*/) {
                let type = this.parentItem.dataItem.controlType.spacerType;

                type.height = formValue["height"];
            }
            else if (controlTypeId === this.controlTypeNames.FormArray/*FormArrayData*/) {
                let type = this.parentItem.dataItem.controlType.formArrayDataControlType;

                type.formArrayFieldLabelKeys = this.dynamicGridComponent.items;
            }

            this.modalSave.emit({ isSuccess: true, parentId: this.parentItem.dataItem });
        }
        else if (this.modalFormComponents[0]?.parentItem !== undefined && this.modalFormComponents[0]?.parentItem.action === this.l("MoveToChild")) {
            let item = this.modalFormComponents[0]?.parentItem.items.find(x => x.id === this.dynamicFormComponent.dynamicForm.value.menuName);

            this.modalSave.emit({ isSuccess: true, selectedItem: item, itemToMove: this.modalFormComponents[0]?.parentItem.dataItem });
        }
        else {
            this.gridIsLoading(true);
            let request = new SaveDynamicDataRequest();

            var data = {};
            if (this.dynamicFormComponent) {
                var value = this.dynamicFormComponent.dynamicForm.value;
                for (var key in value) {
                    if (value.hasOwnProperty(key)) {
                        data[key] = value[key];
                    }
                }

                console.log(data);
            }

            if (this.dynamicGridComponent) {
                data["list"] = this.dynamicGridComponent.items;
            }

            request.dataSourceName = this.parentItem.dataSource;
            request.action = this.parentItem.dataItem.dynamicChildren.length === 0 ? "create" : "update";
            request.data = data;

            this._dynamicDataServiceProxy.saveDynamicData(request)
                .pipe(
                    finalize(() => {
                        this.cd.markForCheck();
                    })
                ).subscribe(result => {
                    if (result.data !== null) {
                        this.gridIsLoading(false);
                        this.modalSave.emit({ isSuccess: true });
                    }
                });
        }
    }

    saveDynamicForm(): void {
    }
}
