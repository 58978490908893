import { Component, Injector, Input} from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CreateDomainRequest, DomainServiceProxy, DynamicDataServiceProxy, SaveDynamicDataRequest } from '@shared/service-proxies/service-proxies';
import { of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { AppAuthService } from '../../auth/app-auth.service';

@Component({
    selector: 'dynamic-import-data',
    templateUrl: './dynamic-import-data.component.html',
    styleUrls: ['./dynamic-import-data.component.scss']
})
export class DynamicImportDataComponent extends AppComponentBase {
    @Input() actionButtonStructure: any;
    @Input() dynamicFormComponents: any;
    disableImportButton: boolean = false;
    isSuccess: boolean = false;

    constructor(
        injector: Injector,
        private _dynamicDataServiceProxy: DynamicDataServiceProxy,
        private _domainServiceProxy: DomainServiceProxy,
        private _appAuthService: AppAuthService
    ) {
        super(injector);
    }

    ngOnInit(): void {
    }

    importData() {
        let domainRequest = new CreateDomainRequest();
        domainRequest.domain = "streamlinea.com";
        domainRequest.recordType = "CNAME";
        domainRequest.subdomain = this.dynamicFormComponents[0].otherData['sls_client_in_process$subdomain'];
        domainRequest.ttl = 600;

        let request = new SaveDynamicDataRequest();
        request.action = "save";
        request.dataSourceName = "InitiateImportData";
        request.data = {};

        this._domainServiceProxy.createSubdomain(domainRequest)
            .pipe(
                catchError(() => {
                    return of(undefined);
                })
            ).subscribe(response => {
                console.log(response);
                domainRequest.recordType = "A";
                domainRequest.subdomain += ".api";

                this._domainServiceProxy.createSubdomain(domainRequest)
                    .pipe(
                        catchError(() => {
                            return of(undefined);
                        })
                    ).subscribe(response => {
                        console.log(response);
                        this._dynamicDataServiceProxy.saveDynamicData(request)
                            .pipe(
                                catchError(() => {
                                    return of(undefined);
                                }),
                                finalize(() => {
                                    if (this.isSuccess) {
                                        this.message.success(this.l("ImportSuccess"));
                                        this._appAuthService.logout(true, "account/login", false);
                                    }
                                    else {
                                        this.message.error(this.l("ImportFail"));
                                    }
                                })
                            ).subscribe(response => {
                                if (response.data.result) {
                                    this.disableImportButton = true;
                                    this.isSuccess = true;
                                }
                                else {
                                    this.isSuccess = false;
                                }
                            });
                    })
            });
    }

    checkForm() {
        return !this.dynamicFormComponents[0]?.dynamicForm?.valid;
    }
}
