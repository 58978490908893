import {
    Component,
    Input,
    TemplateRef,
    ViewChild,
    ElementRef,
    forwardRef,
    Output,
    EventEmitter
} from '@angular/core';
import { ToolBarToolComponent } from '@progress/kendo-angular-toolbar';

export function outerWidth(element: any): number {
    let width = element.offsetWidth;
    const style = getComputedStyle(element);

    width += (parseFloat(style.marginLeft) || 0 + parseFloat(style.marginRight) || 0);
    return width;
}

@Component({
    providers: [{ provide: ToolBarToolComponent, useExisting: forwardRef(() => ToolbarPagerComponent) }],
    selector: 'toolbar-pager',
    template: `
            <ng-template #toolbarTemplate>
                <span #toolbarElement>
                    <input #pageInput type="text" (focusout)="onFocusout($event)" (keydown)="onKeydown($event)" [value]="page" class="text-center" style="width:50px;"/>
                    <label>&nbsp;&nbsp;/&nbsp;{{ totalPage }}&nbsp;&nbsp;</label>
                    
                </span>
            </ng-template>
            `
})
export class ToolbarPagerComponent extends ToolBarToolComponent {
    @Input() public totalPage: number = 0;

    currentPage: number = 0;
    @Input() public get page(): number {
        return this.currentPage;
    }

    @Output() public pageChange = new EventEmitter();
    public set page(val: number) {
        this.currentPage = val;
        this.pageChange.emit(this.currentPage);
    }

    @ViewChild('toolbarTemplate') public toolbarTemplate: TemplateRef<any>;
    @ViewChild('toolbarElement') public toolbarElement: ElementRef;
    @ViewChild('pageInput') public pageInputElement: ElementRef;

    public get outerWidth(): number {
        if (this.toolbarElement) {
            return outerWidth(this.toolbarElement.nativeElement);
        }
    }

    public onFocusout(event) {
        event.srcElement.value = this.page;
    }

    public onKeydown(event) {
        switch (event.key) {
            case "Enter":
                const textValue = event.srcElement.value;
                if (textValue && (textValue >= 1 && textValue <= this.totalPage)) {
                    this.page = textValue;
                }
                else {
                    event.srcElement.value = this.page;
                }
                break;
            case "0":
            case "1":
            case "2":
            case "3":
            case "4":
            case "5":
            case "6":
            case "7":
            case "8":
            case "9":
            case "Backspace":
                break;
            default:
                event.preventDefault();
        }
    }
}
