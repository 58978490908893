export class AppConsts {

    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';

    static remoteServiceBaseUrl: string;
    static remoteServiceBaseUrlFormat: string;
    static appBaseUrl: string;
    static grpcUiServerUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static appBaseUrlFormat: string;
    static recaptchaSiteKey: string;
    static subscriptionExpireNootifyDayCount: number;

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'Streamlinea'
    };

    static readonly authorization = {
        encrptedAuthTokenName: 'enc_auth_token'
    };

    static readonly grid = {
        defaultPageSize: 10
    };

    static readonly MinimumUpgradePaymentAmount = 1;

    /// <summary>
    /// Gets current version of the application.
    /// It's also shown in the web page.
    /// </summary>
    static readonly WebAppGuiVersion = '3.0.0';

    static readonly erpType = {
        r365: '1',
        sage: '2'
    };

    static readonly client = {
        spintest: 'spintest',
        spinstage: 'spinstage',
        spin: 'spin',
        vallstest: 'vallstest',
        vallsstage: 'vallsstage',
        valls: 'valls'
    };

    static readonly actionType = {
        create: 'create',
        read: 'read',
        update: 'update'
    };

    static readonly dynamic = {
        DynamicGridMaintenance: 'GridStructureDynamicGridMaintenance',
        DynamicGridDesignMaintenance: 'GridStructureDynamicGridDesignMaintenance',
        DynamicFormMaintenance: 'GridStructureDynamicFormMaintenance',
        DynamicFormDesignMaintenance: 'GridStructureDynamicFormDesignMaintenance',
        DynamicFieldMaintenance: 'GridStructureDynamicFieldMaintenance',
        DynamicFormChildGroupFieldSets: 'GridStructureDynamicFormChildGroupFieldSets',
        DynamicActionButtonMaintenance: 'GridStructureDynamicActionButtonMaintenance',
        DynamicPageMaintenance: 'GridStructureDynamicPageMaintenance',
        DynamicTabMaintenance: 'GridStructureDynamicTabMaintenance',
        PageLayoutDefinitions: 'GridStructurePageLayoutDefinitions',
        DynamicNavigationMaintenance: 'GridStructureDynamicNavigationMaintenance',
        AttachDetachDynamicGridComponent: 'GridStructureAttachDetachDynamicGridComponent',
        AttachDetachDynamicFormComponent: 'GridStructureAttachDetachDynamicFormComponent',
        AttachDetachDynamicActionButtonComponent: 'GridStructureAttachDetachDynamicActionButtonComponent',
        AttachDetachDynamicTabComponent: 'GridStructureAttachDetachDynamicTabComponent',
        OneTimePayments: 'GridStructureOneTimePayments',
        AccountEntries: 'GridStructureAccountEntries',
        RushPaymentsGrid: 'GridStructureRushPayments',
        GridStructureNumericControlTypeValidation: 'GridStructureNumericControlTypeValidation',
        GridStructureDateControlTypeValidation: 'GridStructureDateControlTypeValidation',
        GridStructureRadioButtonControlTypeValidation: 'GridStructureRadioButtonControlTypeValidation',
        GridStructureFormArrayDataControlTypeValidation: 'GridStructureFormArrayDataControlTypeValidation'
    };

    static readonly componentType = {
        None: 0,
        Grid: 1,
        Form: 2,
        ActionButton: 3
    };

    static readonly pageType = {
        parentPage: 0,
        gridDetailsPage: 1,
        gridPopUpPage: 2,
        formPopUpPage: 3
    };

    static readonly formControlTypes = {
        formInput: 0,
        formTextArea: 1,
        formNumeric: 2,
        formDate: 3,
        formCombobox: 4,
        formDropdown: 5,
        formCheckbox: 6,
        formRadioButton: 7,
        formFileUpload: 8,
        formDisplayImageLogo: 9,
        formInformationOnly: 10,
        formStaticText: 11,
        formSpacer: 12,
        //formMaskedTextBox: 13,
    };

    static readonly gridControlTypes = {
        gridInput: 0,
        gridNumeric: 2,
        gridDate: 3,
        gridComboBox: 4,
        gridDropDown: 5,
        gridCheckBox: 6,
        gridDocument: 8,
        gridMaskedTextBox: 0,
    };

    static readonly dateOperators = {
        noDate: 0,
        pastThan: 1,
        futureThan: 2,
        between: 3
    };

    static readonly numericOperators = {
        noNumeric: 0,
        addition: 1,
        subtraction: 2,
        multiplication: 3,
        division: 4,
        lessThan: 5,
        greaterThan: 6
    };

    static readonly dynamicPageNameId = {
        paymentDashboard: "00000000-0000-0000-0000-000000000001",
        apAging: "00000000-0000-0000-0000-000000000002",
        selectPayments: "00000000-0000-0000-0000-000000000003",
        approvePayments: "00000000-0000-0000-0000-000000000004",
        viewHistory: "00000000-0000-0000-0000-000000000005",
        exportPayments: "00000000-0000-0000-0000-000000000006",
        syncPayments: "00000000-0000-0000-0000-000000000007",
        oneTimePayments: "00000000-0000-0000-0000-000000000008",
        paymentTemplates: "00000000-0000-0000-0000-000000000009",
        paymentTemplatesCalendar: "00000000-0000-0000-0000-000000000010",
        scheduledPayments: "00000000-0000-0000-0000-000000000011",
    };

    static readonly componentId = {
        paymentDashboardBarChartApAgingAsOf: "00000000-0000-0000-0000-000000000001",
        paymentDashboardBarChartScheduledPaymentsPayThroughDate: "00000000-0000-0000-0000-000000000002",
        apAgingGridApAgingAsOf: "00000000-0000-0000-0000-000000000003",
        apAgingGridApAgingAgingCategory: "00000000-0000-0000-0000-000000000004",
        paymentTemplatesCalendarTemplates: "00000000-0000-0000-0000-000000000005",
        scheduledPaymentsGridTemplates: "00000000-0000-0000-0000-000000000006",
        scheduledPaymentsGridPayThroughDate: "00000000-0000-0000-0000-000000000007",
    };
}
