export class AppEditionExpireAction {
    static DeactiveTenant = 'DeactiveTenant';
    static AssignToAnotherEdition = 'AssignToAnotherEdition';
}

export enum ControlTypeNames {
    TextBox = 0,
    TextArea = 1,
    Numeric = 2,
    Date = 3,
    ComboBox = 4,
    DropDown = 5,
    CheckBox = 6,
    RadioButton = 7,
    FileUpload = 8,
    DisplayImage = 9,
    InformationOnly = 10,
    StaticText = 11,
    Spacer = 12,
    Document = 13,
    FormArray = 14,
    GridArray = 15,
    MaskedTextBox = 16,
}

export enum NumericOperators {
    noNumeric = 0,
    addition = 1,
    subtraction = 2,
    multiplication = 3,
    division = 4,
    lessThan = 5,
    greaterThan = 6
};

export enum PageName {
    OneTimePayments = "OneTimePayments",
    PaymentTemplates = "PaymentTemplates",
}

export enum PageNameId {
    SelectPayments = 1,
    ApprovePayments = 2,
    ViewHistory = 3,
    ExportPayments = 4,
    SyncPayments = 5,
    PaymentDashboardBankAccounts = 6,
    OneTimePayments = 7,
    PaymentTemplates = 8,
    ScheduledPayments = 9,
    ApAging = 10,
    PaymentDashboardNotifications = 11,
    OneTimePaymentsAccountEntries = 12,
    PaymentTemplatesPaymentFilters = 13,
}

export enum GridActionType {
    FilterChange = 'filter-change',
    SortChange = 'sort-change',
    GroupChange = 'group-change',
    ResetToDefaultView = 'reset-to-default-view',
    GroupCollapse = 'group-collapse',
    GroupExpand = 'group-expand',
}