<div class="m-portlet__body">

    <form name="dynamic-form" #dynamicForm="ngForm" novalidate autocomplete="off" (keydown.enter)="$event.preventDefault()">
        <div *ngFor="let modalFormComponent of modalFormComponents; let i=index" class="modal-row-margin">
            <dynamic-form [formStructureDataSourceName]="modalFormComponent.modalFormStructureDataSourceName"
                          [formDataDataSourceName]="modalFormComponent.modalFormDataDataSourceName"
                          [actionType]="actionType"
                          [retrieveDataBy]="parentItem?.id"
                          [useDefaultActionButton]="false"
                          [useParentComponentData]="modalFormComponent.useParentComponentData"
                          [parentItem]="modalFormComponent.parentItem">
            </dynamic-form>
        </div>
        
        <div *ngFor="let modalGridComponent of modalGridComponents; let i=index">
            <dynamic-grid [gridStructureDataSourceName]="modalGridComponent.modalGridStructureDataSourceName"
                          [gridDataDataSourceName]="modalGridComponent.modalGridDataDataSourceName"
                          [useParentComponentData]="modalGridComponent.useParentComponentData"
                          [parentItem]="modalGridComponent.parentItem"
                          [childFieldName]="modalGridComponent?.childFieldName">
            </dynamic-grid>
        </div>

        <dynamic-grid *ngIf="usePreviewGrid === true"
                      [gridStructureDataSourceName]="gridStructureDataSourceName"
                      [gridDataDataSourceName]="gridDataDataSourceName"
                      [previewGridSettings]="previewGridSettings"
                      [gridName]="'PreviewGrid'">
        </dynamic-grid>

        <form *ngIf="formStructureDataSourceName !== '' || (formStructures !== undefined && formStructures.length > 0)" name="dynamic-form" #dynamicForm="ngForm" novalidate autocomplete="off" (ngSubmit)="saveDynamicForm()">
            <div class="modal-row-margin">
                <div *ngFor="let formStructure of formStructures; let i=index">
                    <div>
                        <br *ngIf="i > 0" />
                        <!-- <h4 *ngIf="formStructure.labelIsHidden === false" [innerHtml]="l(formStructure.dynamicFormNameKey)"></h4> -->
                        <dynamic-form   [useDefaultActionButton]="false"
                                        [formStructure]="formStructure">
                        </dynamic-form>
                    </div>
                </div>
            </div>
        </form>

        <div *ngFor="let gridStruct of gridStructures; let i=index">
            <div>
                <br *ngIf="i > 0" />
                <!-- <h5 *ngIf="gridStructure.labelIsHidden === false" [innerHtml]="l(gridStructure.dynamicGridNameKey)"></h5> -->
                <dynamic-grid   [customTemplates]="customTemplates"
                                [gridStructure]="gridStruct">
                </dynamic-grid>
            </div>
        </div>

        <div class="col-md-12 text-right action-buttons">
            <button *ngIf="usePreviewGrid === false" (click)="save()" type="button" class="btn btn-primary" [disabled]="!dynamicForm.form.valid" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')">
                {{l("Save")}}
            </button>
        </div>
    </form>
</div>