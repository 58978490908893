import { AppComponentBase } from "@shared/common/app-component-base";
import { Component, Injector, Output, EventEmitter, Input, HostListener } from "@angular/core";

@Component({
    selector: 'grid-filter',
    templateUrl: './grid-filter.component.html',
    styleUrls: ['./grid-filter.component.scss']
})

export class GridFilterComponent extends AppComponentBase {
    @Input() filterDataSourceItems: any[] = [];
    @Input() selectedFilters: any[] = [];
    @Input() clients: any[] = [];
    @Input() erps: any[] = [];
    @Input() selectedClient: any;
    @Input() selectedErp: any;
    @Input() hasUnsavedChanges: boolean = false;
    @Output() updateDataHandler: EventEmitter<any> = new EventEmitter<any>();
    @Output() openUnsavedChanges: EventEmitter<boolean> = new EventEmitter<boolean>();

    longestErpRetrieved: boolean = false;
    longestClientRetrieved: boolean = false;
    clientStyleApplied: boolean = false;
    erpStyleApplied: boolean = false;
    exportStyleApplied: boolean = false;
    erpWidth: number = 54;
    clientWidth: number = 54;
    listHeight: number = 0;

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.computeComboboxListHeight();
    }

    ngOnInit(): void {
        this.computeComboboxListHeight();
    }

    ngAfterViewChecked(): void {
        let erpComboBox = document.getElementById("erp-combobox");
        let clientComboBox = document.getElementById("client-combobox");

        if (this.erps.length >= 1 && this.longestErpRetrieved == false) {
            this.erpWidth = 54;

            let longestErp = this.erps[0].name;

            for (let i = 1; i < this.erps.length; i++) {
                if (this.erps[i].name.length > longestErp.length) {
                    longestErp = this.erps[i].name;
                }
            }

            this.longestErpRetrieved = true;
            for (let j = 0; j < longestErp.length; j++) {
                if (longestErp[j] == ' ') {
                    this.erpWidth += 4;
                }
                else {
                    this.erpWidth += 8;
                }
            }

            if (erpComboBox != undefined) {
                erpComboBox.setAttribute("style", "width:" + this.erpWidth + "px");
            }

            this.exportStyleApplied = false;
            this.erpStyleApplied = false;
            this.clientStyleApplied = false;
        }

        if (this.clients.length > 1 && this.longestClientRetrieved == false) {
            this.clientWidth = 54;
            let longestClient = this.clients[0].name;

            for (let i = 1; i < this.clients.length; i++) {
                if (this.clients[i].name.length > longestClient.length) {
                    longestClient = this.clients[i].name;
                }
            }

            this.longestClientRetrieved = true;
            for (let j = 0; j < longestClient.length; j++) {
                if (longestClient[j] == ' ') {
                    this.clientWidth += 4;
                }
                else {
                    this.clientWidth += 8;
                }
            }

            clientComboBox.setAttribute("style", "width:" + this.clientWidth + "px");

            this.exportStyleApplied = false;
            this.erpStyleApplied = false;
            this.clientStyleApplied = false;
        }

        if (erpComboBox !== undefined && erpComboBox !== null && this.erpStyleApplied === false) {
            erpComboBox.setAttribute("style", "width:" + this.erpWidth + "px");
            this.erpStyleApplied = true;
        } else {
            this.erpStyleApplied = true;
        }

        if (clientComboBox !== undefined && clientComboBox !== null && this.clientStyleApplied === false) {
            clientComboBox.setAttribute("style", "right:" + (erpComboBox.clientWidth + 60) + "px;width:" + this.clientWidth + "px");
            this.clientStyleApplied = true;
        } else {
            this.clientStyleApplied = true;
        }
    }

    computeComboboxListHeight(): void {
        const listHeight = window.innerHeight - 250;
        this.listHeight = listHeight < 80 ? 80 : listHeight;
    }

    clientChange(comboBoxControl = null): void {
        if (comboBoxControl !== null && comboBoxControl.dataItem !== undefined) {
            comboBoxControl.dataItem = comboBoxControl._previousDataItem;
            comboBoxControl._text = comboBoxControl._previousDataItem.Name;
            this.selectedClient = comboBoxControl.dataItem;
        }

        this.updateDataHandler.emit(
            {
                selectedClient: this.selectedClient,
                selectedErp: this.selectedErp
            });

        this.longestErpRetrieved = false;
        this.clientStyleApplied = false;
    }

    erpChange(comboBoxControl = null): void {
        if (comboBoxControl !== null && comboBoxControl.dataItem !== undefined) {
            comboBoxControl.dataItem = comboBoxControl._previousDataItem;
            comboBoxControl._text = comboBoxControl._previousDataItem.Name;
            this.selectedErp = comboBoxControl.dataItem;
        }

        this.updateDataHandler.emit(
            {
                selectedClient: this.selectedClient,
                selectedErp: this.selectedErp
            });
    }
}