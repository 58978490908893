import { Component, Injector, EventEmitter, Output, Input, SimpleChange } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'unsaved-changes',
    templateUrl: './unsaved-changes.component.html'
})
export class UnsavedChangesComponent extends AppComponentBase {
    @Input() dialogOpened: boolean = false;
    @Input() dialogOpenedBySync: boolean = false;
    @Input() savingChangesFromDialog: boolean = false;
    @Input() usePublish: boolean = false;
    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() discard: EventEmitter<void> = new EventEmitter<void>();
    @Output() save: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    closeDialog(): void {
        this.cancel.emit();
    }

    discardChanges(): void {
        this.discard.emit();
    }

    saveChanges(): void {
        this.save.emit();
    }
}
