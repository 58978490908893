import { Injector, Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { DOCUMENT } from '@angular/common';
import { OffcanvasOptions } from '@metronic/app/core/_base/layout/directives/offcanvas.directive';
import { AppConsts } from '@shared/AppConsts';
import { ToggleOptions } from '@metronic/app/core/_base/layout/directives/toggle.directive';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';

@Component({
    templateUrl: './default-layout.component.html',
    styleUrls: ['./default-layout.component.css'],
    selector: 'default-layout',
    animations: [appModuleAnimation()]
})
export class DefaultLayoutComponent extends ThemesLayoutBaseComponent implements OnInit {

    menuCanvasOptions: OffcanvasOptions = {
        baseClass: 'aside',
        overlay: true,
        closeBy: 'kt_aside_close_btn',
        toggleBy: 'kt_aside_mobile_toggle'
    };

    userMenuToggleOptions: ToggleOptions = {
        target: this.document.body,
        targetState: 'topbar-mobile-on',
        toggleState: 'active'
    };

    @ViewChild('wrapper') wrapper: ElementRef;

    defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-on-' + this.currentTheme.baseSettings.menu.asideSkin + '.svg';
    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;

    constructor(
        injector: Injector,
        @Inject(DOCUMENT) private document: Document,
        _dateTimeService: DateTimeService
    ) {
        super(injector, _dateTimeService);
    }

    ngOnInit() {
        this.installationMode = UrlHelper.isInstallUrl(location.href);
    }

    ngAfterViewInit() {
        if (!this.isSignupSubdomain() || this.appSession.tenancyName.toLowerCase().indexOf("signup") >= 0) {
            this.wrapper?.nativeElement.classList.add('wrapper');

            if (this.appSession.tenancyName.toLowerCase().indexOf("signup") >= 0 && document.body.classList.contains('aside-fixed')) {
                document.body.classList.remove('aside-fixed');
            }
        }
    }

    ngAfterViewChecked() {
        if (this.appSession.tenancyName.toLowerCase().indexOf("signup") >= 0) {
            if (document.body.classList.contains('aside-fixed')) {
                document.body.classList.remove('aside-fixed');
            }
        }
    }

    menuHidden(): boolean {
        return this.appSession.tenancyName.toLowerCase().indexOf("signup") >= 0 || window.location.origin.split('.')[0].indexOf("signup") >= 0;
    }

    isSignupSubdomain(): boolean {
        return window.location.origin.split('.')[0].indexOf("signup") >= 0;
    }
}
