<div [@routerTransition]>

    <div class="m-content">
        <div class="m-portlet m-portlet--mobile">
            <div class="m-portlet__body">

                <form name="dynamic-form" #dynamicForm="ngForm" novalidate autocomplete="off" (ngSubmit)="saveDynamicForm()">
                    <div class="modal-row-margin">
                        <div *ngFor="let formStructure of formStructures; let i=index">
                            <div>
                                <br *ngIf="i > 0" />
                                <dynamic-form   #dynamicForm
                                                [useDefaultActionButton]="false"
                                                [formStructure]="formStructure"
                                                [useParentComponentData]="false"
                                                [parentItem]="formData(formStructure.componentSettings.formName)"
                                                [pageName]="pageName"
                                                [fieldLabelWidth]="fieldLabelWidth"
                                                [fieldControlWidth]="fieldControlWidth"
                                                [groupFieldSetWidth]="groupFieldSetWidth"
                                                [groupFieldSetGap]="groupFieldSetGap"
                                                [showBorder]="showBorder"
                                                [showGuide]="showGuide">
                                </dynamic-form>
                            </div>
                        </div>
                    </div>
                </form>
                
                <div *ngFor="let gridStructure of gridStructures; let i=index">
                    <br *ngIf="i > 0" />
                    <dynamic-grid   #dynamicGrid
                                    [customTemplates]="customTemplates"
                                    [gridStructure]="gridStructure"
                                    [useParentComponentData]="useParentComponentData"
                                    [parentItem]="parentItem">
                    </dynamic-grid>
                </div>

                <div class="col-md-12 action-buttons text-right">
                    <button (click)="confirmPublishChanges()" [buttonBusy]="buttonDisabled" [busyText]="l('PublishingChangesWithThreeDot')" [disabled]="buttonDisabled" class="btn btn-primary">{{l("PublishChanges")}}</button>
                </div>

            </div>
        </div>
    </div>
</div>