import { Component, Injector, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DynamicDataServiceProxy, RetrieveDynamicDataRequest } from '@shared/service-proxies/service-proxies';
import { AddEvent, CancelEvent, CellClickEvent, CellCloseEvent, EditEvent, RemoveEvent, SaveEvent, TreeListComponent } from '@progress/kendo-angular-treelist';
import { AppConsts } from '@shared/AppConsts';
import { catchError, finalize, take } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { DataSourceItems } from '../dynamic-grid/data-source-items';
import { ColumnSettings } from '../dynamic-grid/column-settings.interface';
import { GridSettings } from '../dynamic-grid/grid-settings.interface';
import { cloneDeep as _cloneDeep, merge as _merge } from 'lodash-es';

@Component({
    selector: 'dynamic-treelist',
    templateUrl: './dynamic-treelist.component.html',
    styleUrls: ['./dynamic-treelist.component.scss', '../shared/dynamic-components.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})
export class DynamicTreeListComponent extends AppComponentBase {
    @ViewChild(TreeListComponent) public treelist: TreeListComponent;

    @Input() treelistStructureDataSourceName: string;
    @Input() treelistDataDataSourceName: string;
    @Input() treelistName: string;
    @Input() actionType = AppConsts.actionType.create;
    @Input() retrieveDataBy: string = "";
    @Input() dataSourceItems: DataSourceItems[] = [];
    @Output() onSelectEmitter: EventEmitter<any> = new EventEmitter<any>();

    rootData: any[];
    formGroup: FormGroup;
    editedItem: any;
    autocompleteData: string[] = ["1", "2"];

    items: any[] = [];
    defaultGridSettings: GridSettings;
    gridSettings: GridSettings;
    booleanTreeListSettings: ColumnSettings[];

    constructor(
        injector: Injector,
        private _dynamicDataServiceProxy: DynamicDataServiceProxy,
        private cd: ChangeDetectorRef
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.getData();
    }

    getData(): void {
        let data = {};
        if (this.retrieveDataBy !== "") {
            data['id'] = this.retrieveDataBy;
        }

        let request = new RetrieveDynamicDataRequest();
        request.dataSourceName = this.treelistStructureDataSourceName;
        request.data = data;

        this._dynamicDataServiceProxy.retrieveDynamicData(request)
            .pipe(
                finalize(() => {
                    this.gridIsLoading(false);
                })
            ).subscribe(result => {
                if (result.data !== null) {
                    // this.setAdditionalGridSettings(result.data.componentSettings);

                    result.data.items.forEach(item => {
                        item = this.formatItemDate(item);
                    });

                    this.defaultGridSettings = {
                        state: {},
                        columnsConfig: result.data.items,
                        gridName: "",
                        isDefault: true,
                        gridId: result.data.componentSettings.gridId === undefined ? 0 : result.data.componentSettings.gridId
                    };
                    this.gridSettings = _merge({}, this.defaultGridSettings);

                    this.booleanTreeListSettings = this.gridSettings.columnsConfig.filter(x => x.editor === 'boolean');

                    this.retrieveRequiredData();

                    this.getDynamicDataItems();
                }
            });
    }

    getDynamicDataItems() {
        let data = {};
        if (this.retrieveDataBy !== "") {
            data['id'] = this.retrieveDataBy;
        }
        else {
            data['id'] = "1";
        }

        let request = new RetrieveDynamicDataRequest();
        request.dataSourceName = this.treelistDataDataSourceName;
        request.data = data;

        this._dynamicDataServiceProxy.retrieveDynamicData(request)
            .pipe(
                finalize(() => {
                    this.gridIsLoading(false);
                })
            ).subscribe(result => {
                if (result.data !== null) {
                    result.data.items.forEach(item => {
                        item = this.formatItemDate(item);
                    });

                    this.rootData = result.data.items;
                    this.cd.markForCheck();
                }
            });
    }

    retrieveDynamicMultipleDataItems(dataSourceName = null) {
        let data = {};
        if (this.retrieveDataBy !== "") {
            data['id'] = this.retrieveDataBy;
        }

        let request = new RetrieveDynamicDataRequest();
        request.dataSourceName = dataSourceName;
        request.data = data;

        this._dynamicDataServiceProxy.retrieveDynamicData(request)
            .pipe(
                finalize(() => {
                    this.gridIsLoading(false);
                })
            ).subscribe(result => {
                if (result.data !== null) {
                    result.data.items.forEach(item => {
                        item = this.formatItemDate(item);
                    });

                    this.setItems(dataSourceName, result.data.items);
                }
            });
    }

    setItems(dataSourceName: string, items: any): void {
        let dataSourceItemIndex = this.dataSourceItems.findIndex(x => x.dataSourceName === dataSourceName);

        // Updates the value of the data source item when a new item is retrieved.
        if (dataSourceItemIndex > -1) {
            this.dataSourceItems.splice(dataSourceItemIndex, 1);
        }

        let dataSourceItems = new DataSourceItems();
        dataSourceItems.dataSourceName = dataSourceName;
        dataSourceItems.dataSourceItems = items;
        this.dataSourceItems.push(dataSourceItems);
    }

    getItems(dataSourceName: string = ""): any {
        const isMainItem = dataSourceName === "";

        let result: any[];

        if (isMainItem) {
            result = this.dataSourceItems[0]?.dataSourceItems;
        } else {
            result = this.dataSourceItems.filter(x => x.dataSourceName === dataSourceName).sort((x, y) => y.dataSourceItems.length - x.dataSourceItems.length)[0]?.dataSourceItems;
        }

        return result;
    }

    getItemValue(col: ColumnSettings, dataItem: any): string {
        let name = "";

        if (this.dataSourceItems && this.dataSourceItems.length > 0) {
            const dataSource = this.dataSourceItems.filter(x => x.dataSourceName === col.dataSourceName).sort((x, y) => y.dataSourceItems.length - x.dataSourceItems.length);

            if (dataSource.length > 0) {
                const item = dataSource[0]?.dataSourceItems.filter(y => y[col.valueField] === dataItem);

                if (item.length > 0) {
                    name = item[0]?.[col.textField];
                }
            }
        }

        this.cd.markForCheck();

        return name;
    }

    retrieveRequiredData() {
        const dataSourceNames = this.defaultGridSettings.columnsConfig.filter(x => x.dataSourceName).map(x => x.dataSourceName);

        dataSourceNames.map(dataSourceName => {
            this.retrieveDynamicMultipleDataItems(dataSourceName);
        });
    }

    fetchChildren = (item: any): any[] => {
        item.attachedTo = null;
        item.id = 330;

        this.cd.markForCheck();

        return item;
        // return this.editService.fetchChildren(item.id);
    };

    hasChildren = (item: any): boolean => {
        return item.hasChildren;
    };

    cellClickHandler({ sender, columnIndex, dataItem, isEdited }: CellClickEvent): void {
        if (!isEdited) {
            sender.editCell(dataItem, columnIndex, this.createFormGroup(dataItem));
        }
    }

    cellCloseHandler(e: CellCloseEvent): void {
        const { formGroup, dataItem } = e;
        if (!formGroup.valid) {
            // Prevent closing the edited cell if the form is invalid.
            e.preventDefault();
        } else if (formGroup.dirty) {
            // Reflect changes immediately
            Object.assign(dataItem, formGroup.value);

            // this.editService.update(dataItem);
        }
    }
    addHandler({ sender, parent }: AddEvent = null): void {
        const dataItem: any = {
            attachedTo: parent ? parent.id : null,
        };

        // Expand the parent.
        if (parent) {
            sender.expand(parent);
        }

        this.rootData.push(dataItem);

        // Show the new row editor
        sender.addRow(this.createFormGroup(dataItem), parent);
    }

    editHandler({ sender, dataItem }: EditEvent): void {
        // Close the current edited row, if any.
        this.closeEditor(sender, dataItem);

        // Define all editable fields validators and default values
        this.formGroup = this.createFormGroup(dataItem);

        this.editedItem = dataItem;

        // Put the row in edit mode, with the `FormGroup` build above
        sender.editRow(dataItem, this.formGroup);
    }

    cancelHandler({ sender, dataItem, isNew }: CancelEvent): void {
        // Close the editor for the given row
        sender.closeRow(dataItem, isNew);
    }

    saveHandler({ sender, dataItem, parent, formGroup, isNew }: SaveEvent): void {
        // Collect the current state of the form.
        // The `formGroup` argument is the same as was provided when calling `editRow`.
        const item: any = formGroup.value;

        if (!isNew) {
            // Reflect changes immediately
            Object.assign(dataItem, item);
        } else if (parent) {
            // Update the hasChildren field on the parent node
            parent.hasChildren = true;
        }

        // this.editService
        //     // Publish the update to the remote service.
        //     .save(item, parent, isNew)
        //     .pipe(take(1))
        //     .subscribe(() => {
        //         if (parent) {
        //             // Reload the parent node to reflect the changes.
        //             sender.reload(parent);
        //         }
        //     });
        dataItem.id = 1234;
        dataItem.attachedTo = 330;

        if (parent) {
            // Reload the parent node to reflect the changes.
            sender.reload(parent);
        }
        sender.closeRow(dataItem, isNew);
    }

    removeHandler({ sender, dataItem, parent }: RemoveEvent): void {
        // this.editService
        //     // Publish the update to the remote service.
        //     .remove(dataItem, parent)
        //     .pipe(take(1))
        //     .subscribe(() => {
        //         if (parent) {
        //             // Reload the parent node to reflect the changes.
        //             sender.reload(parent);
        //         }
        //     });

        // Close the cell editor
        sender.cancelCell();
    }

    private closeEditor(treelist: TreeListComponent, dataItem: any = this.editedItem, isNew: boolean = false): void {
        treelist.closeRow(dataItem, isNew);
        this.editedItem = undefined;
        this.formGroup = undefined;
    }

    private createFormGroup(item: any): FormGroup {
        // const group = new FormGroup({
        //     groupFieldSet: new FormControl(item.groupFieldSet, Validators.required),
        //     fieldLabelKey: new FormControl(item.fieldLabelKey, Validators.required)
        // });

        this.formGroup = new FormGroup({});

        this.formGroup.setControl("id", new FormControl(0));
        if (item.attachedTo) {
            this.formGroup.setControl("attachedTo", new FormControl(item.attachedTo));
        }

        if (this.defaultGridSettings.columnsConfig.length > 0) {
            this.defaultGridSettings.columnsConfig.map(field => {
                if (field.field === "dateCreated" || field.field === "dateModified") {
                    this.formGroup.setControl(field.field, new FormControl(new Date()));
                }
                else if (field.editor === "combobox") {
                    this.formGroup.setControl(field.field, new FormControl(undefined));
                }
                else if (field.editor === "boolean") {
                    this.formGroup.setControl(field.field, new FormControl({ value: true, disabled: true }));
                }
                else {
                    this.formGroup.setControl(field.field, new FormControl(''));
                }
            });
        }

        return this.formGroup;
    }

    onSelect($event): void {
        this.onSelectEmitter.emit($event);
    }
}