import { Pipe, PipeTransform } from '@angular/core';
import { AppComponentBase } from '../../../shared/common/app-component-base';

@Pipe({
    name: 'numberFormat'
})
export class NumberFormatPipe extends AppComponentBase implements PipeTransform {

    transform(amount: number, currency?: string): string {
        if (isNaN(amount)) {
            return "";
        }

        if (amount < 0) {
            let result = this.intl.formatNumber(Math.abs(amount), { style: 'currency', currency: currency, currencyDisplay: 'symbol' });
            let parsedCurrency = result.slice(0, 1);
            let parsedAmount = result.slice(1, result.length);

            return parsedCurrency + "(" + parsedAmount + ")";
        }
        else {
            return this.intl.formatNumber(amount, { style: 'currency', currency: currency, currencyDisplay: 'symbol' });
        }
    }

}